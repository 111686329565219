<template>
  <SvgBase iconName="MenuClient" :width="16" :height="21">
    <path
      d="M5.72201 4.69153H6.22201V4.19153V0.5H15.8887V20.5H0.888672V4.69153H5.72201ZM14.682 19.8065H15.182V19.3065V1.69355V1.19355H14.682H7.42867H6.92867V1.69355V5.38508H2.09534H1.59534V5.88508V19.3065V19.8065H2.09534H14.682Z"
      fill="#C4C4C4"
    />
    <path d="M5.66699 8.20508H11.1523V8.89863H5.66699V8.20508Z" fill="#C4C4C4" />
    <path d="M5.66699 12.1016H11.1523V12.7951H5.66699V12.1016Z" fill="#C4C4C4" />
    <path d="M5.66699 16.0391H11.1523V16.7326H5.66699V16.0391Z" fill="#C4C4C4" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMenuClient extends Vue {}
</script>
