import { getProducts, getProductSuppliers } from '@/utils/api'
import { IProduct } from 'smartbarcode-web-core/src/utils/types/index'
import { CLEAR_ALL, CLEAR_LOADING, FETCH_PRODUCTS, FETCH_PRODUCT_SUPPLIERS, SAVE_PRODUCT, SET_LOADING } from './actions'

type TProductState = {
  isLoaded: boolean
  products: IProduct[]
}

export default {
  state: {
    isLoaded: false,
    products: [],
  },
  getters: {
    getProducts: (state: TProductState) => {
      return state.products
    },
  },
  mutations: {
    [SAVE_PRODUCT]: (state: TProductState, payload: IProduct[]) => {
      state.isLoaded = true
      state.products = [...payload]
    },
    [CLEAR_ALL]: (state: TProductState) => {
      state.isLoaded = false
      state.products = []
    },
  },
  actions: {
    [FETCH_PRODUCTS]: async ({ commit }: { commit: Function }) => {
      try {
        const products: IProduct[] = await getProducts()
        commit(SAVE_PRODUCT, products)
      } catch (e) {}
    },

    [FETCH_PRODUCT_SUPPLIERS]: async ({ commit }: { commit: Function }) => {
      try {
        commit(SET_LOADING)
        return await getProductSuppliers()
      } finally {
        commit(CLEAR_LOADING)
      }
    },
  },
}
