import i18n from '@/i18n'
import store from '@/store'
import { OPEN_DIALOG } from '@/store/actions'
export default function errorHandler(error: unknown, fieldTemplate?: Record<string, string>, isRawString?: boolean) {
  const err = error as string | Record<string, Array<string>> | Error
  const t = i18n.global.t
  let errorDetails
  if (isRawString) {
    errorDetails = err
  } else if (typeof err === 'string') {
    errorDetails = t(`errors.${error}`, { ...fieldTemplate })
  } else if (err.message) {
    errorDetails = t(`errors.${err.message}`)
  } else {
    errorDetails = Object.entries(err)
      .map(([field, value]) => {
        if (typeof value === 'object') {
          return value
            .map((errorCode: string) => `[ERR.${errorCode}] ` + t(`errors.${errorCode}`, { field: field }))
            .join('<br />')
        }
      })
      .join('<br />')
  }

  store.dispatch(OPEN_DIALOG, { message: t('error_template', { errors: errorDetails }) })
}
