<template>
  <SvgBase iconName="IconArrowRight" :width="20" :height="18">
    <g fill="none">
      <path
        d="M19.5779 8.0232L9.80524 0L8.29712 1.87007L15.4154 7.72158H0L0 10.1346H15.4154L8.29712 15.9861L9.80524 17.8561L19.5175 9.83295C19.8192 9.59165 19.9398 9.29002 19.9398 8.92807C19.9398 8.56613 19.8192 8.20418 19.5779 8.0232Z"
        fill="#C4C4C4"
      />
    </g>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconArrowRight extends Vue {}
</script>
