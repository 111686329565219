<template>
  <SvgBase iconName="Textarea" width="14" height="14">
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" :stroke="color" />
      <line x1="3.5" y1="3.875" x2="10.5" y2="3.875" :stroke="color" />
      <line x1="3.5" y1="9.125" x2="7" y2="9.125" :stroke="color" />
      <line x1="3.5" y1="6.5" x2="10.5" y2="6.5" :stroke="color" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTextarea extends Vue {}
</script>
