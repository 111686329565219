<template>
  <SvgBase iconName="GhgEmission" width="17" height="15" fill="none">
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8275 1.99896C12.4701 1.38075 11.8102 1 11.0961 1H5.9039C5.1898 1 4.52987 1.38075 4.17245 1.99896L1.57875 6.48519C1.22068 7.10451 1.22068 7.86793 1.57875 8.48726L4.17245 12.9735C4.52987 13.5917 5.1898 13.9724 5.9039 13.9724H11.0961C11.8102 13.9724 12.4701 13.5917 12.8275 12.9735L15.4213 8.48726C15.7793 7.86793 15.7793 7.10452 15.4213 6.48519L12.8275 1.99896Z"
        :stroke="color"
        stroke-miterlimit="10"
      />
      <g clip-path="url(#clip0_12092_4018)">
        <path
          d="M8.51138 7.0597H12V8.8607C11.9829 9.74627 11.6472 10.4876 10.9927 11.0846C10.3325 11.6766 9.50163 11.9851 8.50569 12C7.7374 11.9851 7.08862 11.8159 6.57642 11.4876C6.05285 11.1741 5.67154 10.8159 5.43252 10.408C5.35854 10.2736 5.29593 10.1493 5.23902 10.0249C5.1878 9.9005 5.14228 9.74129 5.10244 9.55224C5.03415 9.19403 5 8.51244 5 7.49751C5 6.48259 5.03415 5.78109 5.10244 5.43284C5.17642 5.08458 5.28455 4.80099 5.43252 4.58706C5.67154 4.1791 6.05285 3.81592 6.57642 3.49254C7.08862 3.17413 7.73171 3.00995 8.50569 3C9.45041 3.00995 10.2244 3.25871 10.822 3.76119C11.4195 4.26368 11.7951 4.88557 11.9545 5.62687H10.378C10.2585 5.26866 10.0366 4.96517 9.71789 4.71144C9.3878 4.47264 8.98374 4.34826 8.50569 4.33831C8.15285 4.34826 7.85691 4.40796 7.60081 4.52736C7.34472 4.64677 7.13984 4.80099 6.98049 4.98507C6.78699 5.17413 6.66179 5.42289 6.59919 5.72139C6.5252 6.0398 6.49106 6.63184 6.49106 7.50249C6.49106 8.37313 6.5252 8.96517 6.59919 9.27363C6.66179 9.58209 6.79268 9.83085 6.98049 10.0199C7.13984 10.204 7.34472 10.3483 7.60081 10.4627C7.85122 10.597 8.15285 10.6617 8.50569 10.6617C9.08618 10.6617 9.56423 10.4826 9.93984 10.1244C10.3154 9.78109 10.5089 9.33333 10.5203 8.77612V8.31343H8.51707V7.04975L8.51138 7.0597Z"
          :fill="color"
        />
      </g>
      <defs>
        <clipPath id="clip0_12092_4018">
          <rect width="7" height="9" fill="white" transform="translate(5 3)" />
        </clipPath>
      </defs>
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconGhgEmission extends Vue {}
</script>
