
<template>
  <SvgBase iconName="Signature" width="14" height="14">
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.625 8.84722C4.24521 7.22701 5.05556 5.93056 5.05556 4.95833C5.05556 3.5 4.56944 3.5 4.08333 3.5C3.59722 3.5 3.09556 4.02743 3.11111 4.95833C3.12764 5.95389 3.91708 7.3291 4.32639 7.875C5.05556 8.84722 5.54167 9.09028 6.02778 8.36111L7 6.90278C7.16187 8.19924 7.64799 8.84722 8.45833 8.84722C8.71597 8.84722 9.74118 7.875 9.91667 7.875C10.168 7.875 10.6541 8.19924 11.375 8.84722"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" :stroke="color" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconSignature extends Vue {}
</script>
