<template>
  <SvgBase iconName="MenuClient" :width="18" :height="16">
    <path d="M12.3902 5.45354V15.0002H2.97461V3.54688H12.3902V5.45354Z" stroke="white" stroke-miterlimit="10" />
    <path d="M4.99023 9.27344H10.3746" stroke="white" stroke-miterlimit="10" />
    <path d="M4.99023 6.72656H10.3746" stroke="white" stroke-miterlimit="10" />
    <path d="M4.99023 11.8203H9.02852" stroke="white" stroke-miterlimit="10" />
    <path d="M15.0824 13.0933V2.90667V1H4.99023" stroke="white" stroke-miterlimit="10" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMenuManual extends Vue { }
</script>
