import { getStatistics } from '@/utils/api'
import { CLEAR_LOADING, FETCH_STATISTICS, SET_LOADING } from './actions'

export default {
//  state: {},
//  getters: {},
//  mutations: {},
 actions: {
  [FETCH_STATISTICS]: async ({ commit }: {
    commit: Function
    dispatch: Function
  }, payload: Record<string, string | string[]>) => {
    try {
      commit(SET_LOADING)
      return await getStatistics(payload)
    } finally {
      commit(CLEAR_LOADING)
    }
  },
 },
}
