import {
  getTimetable,
  getTimetableSchedule,
  uploadTimetableCsv,
  deleteTimeTable,
  acknowledgeTimetable,
  updateTimeTable,
} from '@/utils/api'
import {
  IMPORT_TIMETABLE_CSV,
  LOAD_TIMETABLE,
  LOAD_TIMETABLE_SCHEDULE,
  LOAD_TIMETABLE_SCHEDULE_SUCCESS,
  DELETE_TIME_TABLE,
  ACKNOWNLEDGE_TIMETABLE,
  UPDATE_TIMETABLE_TIME,
  TIMETABLE_IS_EDIT_TIME,
  CLEAR_ALL,
} from './actions'
import {
  IGetTimetablePayload,
  IGetTimetableSchedulePayload,
  ITimeTableSchedule,
  ITimeTable,
  ITimetableUpdateNode,
} from 'smartbarcode-web-core/src/utils/types/index'
import { displayMomentAtParam } from '@/utils/timeUtils'
import moment from 'moment-timezone'

type TTimetableState = {
  selectedDate: string
  timetable: ITimeTable | null
  timetableSchedules: ITimeTableSchedule[] | null
  isEditNode: boolean
}

export default {
  state: {
    selectedDate: displayMomentAtParam(moment()),
    timetable: {},
    timetableSchedules: [],
    isEditNode: false,
  },
  getters: {},
  mutations: {
    [LOAD_TIMETABLE]: (state: TTimetableState, data: { timetable: ITimeTable; selectedDate: string }) => {
      state.timetable = data.timetable
      state.selectedDate = data.selectedDate
    },
    [LOAD_TIMETABLE_SCHEDULE_SUCCESS]: (state: TTimetableState, timetableSchedules: ITimeTableSchedule[]) => {
      state.timetableSchedules = timetableSchedules || []
    },
    [TIMETABLE_IS_EDIT_TIME]: (state: TTimetableState, status: boolean) => {
      state.isEditNode = status
    },
    [CLEAR_ALL]: (state: TTimetableState) => {
      state.timetable = null
      state.timetableSchedules = []
    },
  },
  actions: {
    [IMPORT_TIMETABLE_CSV]: async (
      {
        commit,
      }: {
        commit: Function
      },
      {
        file,
      }: {
        file: Blob
      }
    ) => {
      const formData = new FormData()
      formData.append('csv', file)

      let result = ''
      await uploadTimetableCsv(formData, () => false)
        .then((res) => {
          if (res[0] && res[0].date) {
            result = res[0].date
          }
        })
        .catch((error) => {
          throw error
        })

      return result
    },
    [LOAD_TIMETABLE]: async (
      {
        commit,
      }: {
        commit: Function
      },
      payload: IGetTimetablePayload
    ) => {
      try {
        const timetable = await getTimetable(payload.date)
        commit(LOAD_TIMETABLE, { timetable, selectedDate: payload.date })
      } catch (err) {
        commit(LOAD_TIMETABLE, { timetable: {}, selectedDate: payload.date })
        throw err
      }
    },
    [LOAD_TIMETABLE_SCHEDULE]: async (
      {
        commit,
      }: {
        commit: Function
        dispatch: Function
        state: TTimetableState
      },
      payload: IGetTimetableSchedulePayload
    ) => {
      try {
        const timetable = await getTimetableSchedule(payload.timetableId)
        commit(LOAD_TIMETABLE_SCHEDULE_SUCCESS, { ...timetable })
      } catch (error) {
        commit(LOAD_TIMETABLE_SCHEDULE_SUCCESS, {})
        throw error
      }
    },
    [ACKNOWNLEDGE_TIMETABLE]: async (
      {
        dispatch,
      }: {
        dispatch: Function
      },
      data: { payload: ITimetableUpdateNode; selectedDate: string }
    ) => {
      await acknowledgeTimetable(data.payload).catch((err) => {
        throw err
      })
      await dispatch(LOAD_TIMETABLE, { date: data.selectedDate })
    },
    [UPDATE_TIMETABLE_TIME]: async (
      {
        dispatch,
      }: {
        dispatch: Function
        state: TTimetableState
      },
      data: { payload: ITimetableUpdateNode; selectedDate: string }
    ) => {
      await updateTimeTable(data.payload)
        .then(() => {
          // Get data after update to update screen
          dispatch(LOAD_TIMETABLE, { date: data.selectedDate })
        })
        .catch((err) => {
          throw err
        })
    },
    [DELETE_TIME_TABLE]: async (
      {
        dispatch,
      }: {
        dispatch: Function
      },
      data: { id: string; selectedDate: string }
    ) => {
      await deleteTimeTable(data.id).catch((err) => {
        throw err
      })
      await dispatch(LOAD_TIMETABLE, { date: data.selectedDate })
    },
  },
}
