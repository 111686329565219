/* eslint-disable no-useless-catch */
import { fetchStaff, getUserGroups } from '@/utils/api'
import { ICreateUserGroup, IStaff } from 'smartbarcode-web-core/src/utils/types/index'

import { FETCH_STAFF, CLEAR_ALL, IS_ACTIVE_CARRIER } from './actions'

type TCarrierState = {
  isShowOnlyActive: boolean
  staffs: IStaff[]
  permissionGroup: []
  isLoaded: boolean
}

export default {
  state: {
    isShowOnlyActive: true,
    staffs: [],
    permissionGroup: [],
    isLoaded: false,
  },
  mutations: {
    [IS_ACTIVE_CARRIER]: (state: TCarrierState, isShowOnlyActive: boolean) => {
      state.isShowOnlyActive = isShowOnlyActive
    },
    [FETCH_STAFF]: (state: TCarrierState, payload: IStaff[]) => {
      if (payload) {
        state.staffs = payload
        state.isLoaded = true
      }
    },
    [CLEAR_ALL]: (state: TCarrierState) => {
      state.staffs = []
      state.permissionGroup = []
      state.isLoaded = false
    },
  },
  actions: {
    [FETCH_STAFF]: async ({ commit }: { commit: Function }) => {
      try {
        const [data, groups] = await Promise.all([fetchStaff(), getUserGroups()])
        data.map((staff: IStaff) => {
          staff.groups = []
          if (staff.userGroupIds) {
            staff.userGroupIds.forEach((groupId) => {
              groups.forEach((element: ICreateUserGroup) => {
                if (element.id === groupId) {
                  staff.groups && staff.groups.push(element)
                }
              })
            })
          }
        })
        commit(FETCH_STAFF, data)
      } catch (err) {
        throw err
      }
    },
  },
}
