<template>
  <SvgBase iconName="IconStockroom" width="32" height="32" fill="white">
    <!-- <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> -->
    <g clip-path="url(#clip0_11893_6262)">
      <path d="M4.40294 2.62305H0.790039V30.3759H4.40294V2.62305Z" fill="white" stroke-width="1.5" />
      <path d="M31.2203 2.62305H27.6074V30.3759H31.2203V2.62305Z" fill="white" stroke-width="1.5" />
      <path d="M4.69824 6.18945H27.3026" stroke-width="1.5" />
      <path d="M4.69824 13.2324H27.3026" stroke-width="1.5" />
      <path d="M4.69824 20.2734H27.3026" stroke-width="1.5" />
      <path d="M4.69824 27.3164H27.3026" stroke-width="1.5" />
    </g>
    <defs>
      <clipPath id="clip0_11893_6262">
        <rect width="32" height="29" :fill="color" transform="translate(0 2)" />
      </clipPath>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'
@Options({
  components: { SvgBase },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconStockroom extends Vue {}
</script>
