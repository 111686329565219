<template>
  <SvgBase iconName="Email" width="14" height="14">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.92083 7.90655C8.92083 8.25699 9.02042 8.39675 9.28083 8.39675C9.86125 8.39675 10.2308 7.65624 10.2308 6.4247C10.2308 4.54234 8.86083 3.64122 7.15042 3.64122C5.39083 3.64122 3.79042 4.82269 3.79042 7.05549C3.79042 9.18815 5.19042 10.3496 7.34042 10.3496C8.07042 10.3496 8.56042 10.2695 9.31 10.0192L9.47083 10.6896C8.73083 10.9303 7.94 11 7.33042 11C4.51042 11 3 9.44806 3 7.05507C3 4.64205 4.75042 3 7.16042 3C9.67042 3 11 4.50188 11 6.34418C11 7.90613 10.5104 9.09762 8.97042 9.09762C8.27 9.09762 7.81042 8.81727 7.75042 8.19608C7.57042 8.88694 7.09042 9.09762 6.44 9.09762C5.57 9.09762 4.84 8.42637 4.84 7.07509C4.84 5.71339 5.48042 4.87234 6.63042 4.87234C7.24042 4.87234 7.62042 5.11264 7.78958 5.49312L8.08 4.96245H8.92V7.90655H8.92083V7.90655ZM7.69125 6.5849C7.69125 6.03463 7.28083 5.80392 6.94083 5.80392C6.57083 5.80392 6.16125 6.10388 6.16125 6.9854C6.16125 7.68627 6.47125 8.07676 6.94083 8.07676C7.27083 8.07676 7.69125 7.8665 7.69125 7.28577V6.5849Z"
        :fill="color"
      />
      <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" :stroke="color" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'
@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconEmail extends Vue {}
</script>
