<template>
  <SvgBase iconName="Image" width="14" height="14">
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.95611 4.375L6.62278 9.46452L4.95833 5.37056L2.625 10.4523H11.9583L8.95611 4.375Z"
        :fill="color"
      />
      <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" :stroke="color" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconImage extends Vue {}
</script>
