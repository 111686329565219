
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  name: 'NotFound',
})
export default class NotFound extends Vue {
  @Prop({ type: String }) readonly item: string = 'item'
}
