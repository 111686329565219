/* eslint-disable no-useless-catch */
import { getClient, getClientList } from '@/utils/api'

import { getInitClientDetailState } from '@/utils/helpers'
import { IClient, IClientList } from 'smartbarcode-web-core/src/utils/types/index'
import {
  CLEAR_ALL,
  CLEAR_DETAIL_CLIENT,
  FETCH_CLIENT,
  IS_ACTIVE_CLIENT,
  LOAD_CLIENT_LIST,
  SAVE_CLIENT,
} from './actions'

export interface IStoreAction {
  commit: Function
  dispatch: Function
  state: TClientStates
}
type TClientStates = {
  isShowOnlyActive: boolean
  isLoaded: boolean
  clients: Array<IClientList>
  client: IClient
}

export default {
  state: {
    isShowOnlyActive: true,
    isLoaded: false,
    clients: [],
    client: getInitClientDetailState(),
  },
  mutations: {
    [IS_ACTIVE_CLIENT]: (state: TClientStates, isActive: boolean) => {
      state.isShowOnlyActive = isActive
    },
    [LOAD_CLIENT_LIST]: (
      state: TClientStates,
      {
        clients,
      }: {
        clients: Array<IClientList>
      }
    ) => {
      state.isLoaded = true
      state.clients = clients
    },
    [SAVE_CLIENT]: (state: TClientStates, client: IClient) => {
      state.client = { ...client }
    },
    [CLEAR_DETAIL_CLIENT]: (state: TClientStates) => {
      state.client = getInitClientDetailState()
    },
    [CLEAR_ALL]: (state: TClientStates) => {
      state.isLoaded = false
      state.clients = []
      state.client = getInitClientDetailState()
    },
  },
  actions: {
    [LOAD_CLIENT_LIST]: async ({ commit }: IStoreAction) => {
      try {
        const clients: Array<IClientList> = await getClientList()
        commit(LOAD_CLIENT_LIST, { clients })
      } catch (err) {
        throw err
      } finally {
      }
    },

    [FETCH_CLIENT]: async (
      {
        commit,
      }: {
        commit: Function
        dispatch: Function
        state: TClientStates
      },
      clientId: string
    ) => {
      try {
        const response = (await getClient(clientId)) as IClientList

        const client = {
          isEdit: true,
          clientName: response.client.name,
          address: response.client.address,
          clientUser: {
            visibleProjectCodes: response.clientUser?.visibleProjectCodes,
            id: response.clientUser.id,
            condition: response.clientUser.condition,
            setPresetSearch: response.clientUser.setPresetSearch,
            email: response.clientUser.email,
            password: '',
            firstName: response.clientUser.firstName,
            lastName: response.clientUser.lastName,
            carrierIds: response.clientUser.carrierIds,
            phone: {
              number: response.clientUser.phone.number,
              countryCode: response.clientUser.phone.countryCode,
            },
            roleType: response.clientUser.roleType,
          },
          isActive: response.client.isActive,
        } as IClient
        commit(SAVE_CLIENT, client)
      } catch (err) {
        throw err
      } finally {
      }
    },
  },
}
