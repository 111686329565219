<template>
  <SvgBase iconName="MenuTimetable" :width="24" :height="19">
    <path d="M7.55769 0.75H0.75V17.7115H7.55769V0.75Z" fill="none" />
    <path d="M23.2501 0.75H16.4424V17.7115H23.2501V0.75Z" fill="none" />
    <path d="M12.0549 5.99981L14.8012 8.74609L12.0549 11.4924" fill="none" />
    <path d="M7.75 8.91895H13.6346" fill="none" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMenuTimeTable extends Vue {}
</script>
