import { IAddressResponse } from 'smartbarcode-web-core/src/utils/types/index'
import { CLEAR_ALL, SAVE_ADDRESSES } from './actions'

type TAddressState = {
  isLoaded: boolean
  addresses: IAddressResponse
}

export default {
  state: {
    isLoaded: false,
    addresses: {
      addressHistory: [],
      addressBook: [],
    },
  },
  getters: {},
  mutations: {
    [SAVE_ADDRESSES]: (state: TAddressState, payload: IAddressResponse) => {
      state.addresses = { ...payload }
      state.isLoaded = true
    },
    [CLEAR_ALL]: (state: TAddressState) => {
      state.isLoaded = false
      state.addresses = {
        addressHistory: [],
        addressBook: [],
      }
    },
  },
  actions: {},
}
