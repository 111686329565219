/* eslint-disable no-useless-catch */
import { fetchStaff, getProjectsList, getUserGroup, getUserGroups } from '@/utils/api'
import { CARRIER_USER_GROUP_DEFAULT } from '@/utils/constants'
import {
  ICarrierProject,
  ICarrierUserGroup,
  IProject,
  IStaff,
  ITrackingPermissions,
} from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'

import {
  FETCH_USER_GROUPS,
  CLEAR_ALL,
  IS_ACTIVE_USER_GROUP,
  FETCH_USER_GROUP,
  FETCH_USER_GROUP_PROJECTS,
  UPDATE_USER_GROUP_PERMISSION,
  DELETE_USER_GROUP_PERMISSION,
  UPDATE_USER_LIST,
  FETCH_USER_GROUP_ADD,
} from './actions'

type TUserGroupState = {
  isShowOnlyActive: boolean
  userGroups: ICarrierUserGroup[]
  isLoaded: boolean
  userGroup: ICarrierUserGroup
  staffs: IStaff[]
  projects: ICarrierProject[]
}

export default {
  state: {
    isShowOnlyActive: true,
    userGroups: [],
    isLoaded: false,
    userGroup: cloneDeep(CARRIER_USER_GROUP_DEFAULT),
    projects: [],
  },
  mutations: {
    [IS_ACTIVE_USER_GROUP]: (state: TUserGroupState, isShowOnlyActive: boolean) => {
      state.isShowOnlyActive = isShowOnlyActive
    },
    [FETCH_USER_GROUPS]: (state: TUserGroupState, payload: ICarrierUserGroup[]) => {
      if (payload) {
        state.userGroups = payload
        state.isLoaded = true
      }
    },
    [CLEAR_ALL]: (state: TUserGroupState) => {
      state.userGroups = []
      state.isLoaded = false
    },
    [FETCH_USER_GROUP]: (
      state: TUserGroupState,
      payload: {
        userGroup: ICarrierUserGroup
        staffs: IStaff[]
      }
    ) => {
      if (payload) {
        state.userGroup = payload.userGroup
        state.staffs = payload.staffs
      }
    },
    [FETCH_USER_GROUP_ADD]: (state: TUserGroupState, payload: IStaff[]) => {
      if (payload) {
        state.staffs = payload
        state.userGroup = cloneDeep(CARRIER_USER_GROUP_DEFAULT)
      }
    },
    [FETCH_USER_GROUP_PROJECTS]: (state: TUserGroupState, payload: ICarrierProject[]) => {
      if (payload) {
        state.projects = payload
      }
    },
    [UPDATE_USER_GROUP_PERMISSION]: (state: TUserGroupState, payload: ITrackingPermissions) => {
      if (payload) {
        state.userGroup.projectPermissions = payload
      }
    },
    [UPDATE_USER_LIST]: (state: TUserGroupState, payload: string[]) => {
      if (payload) {
        state.userGroup.userIds = payload
      }
    },
    [DELETE_USER_GROUP_PERMISSION]: (state: TUserGroupState, projectId: string) => {
      if (state.userGroup.projectPermissions?.[projectId]) {
        delete state.userGroup.projectPermissions[projectId]
      }
    },
  },
  actions: {
    [FETCH_USER_GROUPS]: async ({ commit }: { commit: Function }) => {
      try {
        const [staffs, groups] = await Promise.all([fetchStaff(), getUserGroups()])
        groups.map((userGroup: ICarrierUserGroup) => {
          userGroup.userIds = [] as string[]
          staffs.forEach((item: IStaff) => {
            if (item.isActive && item.userGroupIds?.includes(userGroup.id || '')) {
              userGroup.userIds.push(item.id as string)
            }
          })
        })
        commit(FETCH_USER_GROUPS, groups)
      } catch (err) {
        throw err
      }
    },
    [FETCH_USER_GROUP]: async (
      {
        commit,
      }: {
        commit: Function
      },
      userGroupId: string
    ) => {
      try {
        let [staffs, userGroup] = await Promise.all([fetchStaff(), getUserGroup(userGroupId)])
        userGroup.userIds = [] as string[]
        staffs.forEach((item: IStaff) => {
          if (item.userGroupIds?.includes(userGroup.id || '')) {
            userGroup.userIds.push(item.id || '')
          }
        })

        staffs = staffs.filter((item: IStaff) => {
          return item.isActive
        })

        commit(FETCH_USER_GROUP, {
          userGroup,
          staffs,
        })
      } catch (err) {
        throw err
      }
    },
    [FETCH_USER_GROUP_ADD]: async ({ commit }: { commit: Function }) => {
      try {
        let staffs: IStaff[] = (await fetchStaff()) as IStaff[]
        staffs = staffs.filter((item) => {
          return item.isActive
        })
        commit(FETCH_USER_GROUP_ADD, staffs)
      } catch (err) {
        throw err
      }
    },
    [FETCH_USER_GROUP_PROJECTS]: async ({ commit }: { commit: Function }) => {
      try {
        const result = await getProjectsList(false, false)
        const projects = result.projects as IProject[]
        const carrierProjects = [] as ICarrierProject[]
        projects.forEach((project: IProject) => {
          carrierProjects.push({
            id: project.id,
            name: project.name || '',
            code: project.code,
            version: project.version || '',
          } as ICarrierProject)
        })
        commit(FETCH_USER_GROUP_PROJECTS, carrierProjects)
      } catch (err) {
        throw err
      }
    },
  },
}
