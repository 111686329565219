/* eslint-disable no-useless-catch */

import { getLocation, getLocationList, getLocationPermissions } from '@/utils/api'
import { ILocation, ILocationPermissionPayload, IPaginationPayload } from 'smartbarcode-web-core/src/utils/types/index'
import {
  CLEAR_ALL,
  CLEAR_DETAIL_LOCATION,
  FETCH_LOCATION,
  IS_ACTIVE_LOCATION,
  LOAD_LOCATION_LIST,
  LOAD_LOCATION_PERMISSIONS,
  SAVE_LOCATION,
} from './actions'

type TLocationStates = {
  isShowOnlyActive: boolean
  isLoaded: boolean
  locations: ILocation[]
  totalLocation: number
  location: ILocation
}

function getInitLocationDetailState(): ILocation {
  return {} as ILocation
}

export default {
  state: {
    isShowOnlyActive: true,
    isLoaded: false,
    locations: [],
    totalLocation: 0,
    location: getInitLocationDetailState(),
  },
  mutations: {
    [IS_ACTIVE_LOCATION]: (state: TLocationStates, isActive: boolean) => {
      state.isShowOnlyActive = isActive
    },
    [LOAD_LOCATION_LIST]: (
      state: TLocationStates,
      {
        locations,
        count,
      }: {
        locations: ILocation[]
        count: number
      }
    ) => {
      state.isLoaded = true
      state.locations = locations
      state.totalLocation = count
    },
    [SAVE_LOCATION]: (state: TLocationStates, location: ILocation) => {
      state.location = { ...location }
    },
    [CLEAR_DETAIL_LOCATION]: (state: TLocationStates) => {
      state.location = getInitLocationDetailState()
    },
    [CLEAR_ALL]: (state: TLocationStates) => {
      state.isLoaded = false
      state.locations = []
      state.location = getInitLocationDetailState()
    },
  },
  actions: {
    [LOAD_LOCATION_LIST]: async (
      {
        commit,
      }: {
        commit: Function
        dispatch: Function
        state: TLocationStates
      },
      payload: IPaginationPayload
    ) => {
      try {
        const res = await getLocationList(payload)
        const count = res.count
        const locations = res.results as ILocation[]
        commit(LOAD_LOCATION_LIST, { locations, count })
      } catch (err) {
        throw err
      } finally {
      }
    },
    [LOAD_LOCATION_PERMISSIONS]: async (
      {
        commit,
      }: {
        commit: Function
        dispatch: Function
        state: TLocationStates
      },
      payload: ILocationPermissionPayload
    ) => {
      let permissions = []
      let total = 0
      let projects = []
      try {
        const res = await getLocationPermissions(payload)
        permissions = res.results
        total = res.count
        projects = res.existsProjects
      } catch (err) {
        throw err
      }
      return { permissions, total, projects }
    },

    [FETCH_LOCATION]: async (
      {
        commit,
        state,
      }: {
        commit: Function
        dispatch: Function
        state: TLocationStates
      },
      locationId: string
    ) => {
      try {
        if (!locationId) return
        return await getLocation(locationId)

        // commit(SAVE_LOCATION, location)
      } catch (err) {
        throw err
      } finally {
      }
    },
  },
}
