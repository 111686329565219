<template>
  <div v-if="isHaveData" class="flex-grow flex flex-col">
    <a :href="fileUrl" ref="btnDownloadTemplate" target="__blank" download></a>
    <div class="mb-8 flex-grow table-container">
      <el-table
        v-loading="loading"
        :data="exportRecord.data"
        class="rounded-xl w-full"
        header-cell-class-name="custom-table__header-cell"
        cell-class-name="custom-table__cell cursor-pointer"
        border
        :empty-text="$t('empty')"
        highlight-current-row
      >
        <el-table-column prop="projectCode" :label="`${$t('projects.projectCode')} / ${$t('file_name')}`">
          <template v-slot="scope">
            <div>{{ `${scope.row.projectCode} ${scope.row.fileName ? `/ ${scope.row.fileName}` : ''}` }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('barcode_type.ouput_format')" :min-width="18" prop="fileFormat">
          <template v-slot="scope">
            <div v-if="scope.row.operationType === 'reportGenerate'">
              {{ $t(`file_processing.${scope.row.operationType}`) }}
              (<span class="uppercase">{{ scope.row.fileFormat }}</span
              >)
            </div>
            <div v-else>
              <span class="uppercase">{{ scope.row.fileFormat }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('barcodeImport.progress')" :min-width="18" width="200" prop="status">
          <template v-slot="scope">
            {{ scope.row.status ? $t(`barcode_status.${scope.row.status}`) : '' }}
            {{ scope.row.status == 'processing' ? `(${scope.row.progress}%)` : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="audit.createdDateTime" :label="$t('barcode.createdDate')" width="100">
          <template v-slot="scope">{{ displayDateTime(scope.row.audit?.createdDateTime) }}</template>
        </el-table-column>
        <el-table-column prop="audit.updatedDateTime" :label="$t('barcodeImport.completedDate')" width="100">
          <template v-slot="scope">{{ displayDateTime(scope.row.audit?.processedDateTime) }}</template>
        </el-table-column>
        <el-table-column prop="count" :label="$t('file_processing.number')" width="80" />
        <el-table-column prop="fileSize" :label="$t('file_processing.size')" width="100">
          <template v-slot="scope">{{ displayFileSize(scope.row.fileSize) }}</template>
        </el-table-column>
        <el-table-column prop="" label="" width="60">
          <template v-slot="scope">
            <div class="w-full flex justify-center">
              <component
                :is="getIcon(scope.row.fileFormat)"
                @click="downloadTemplate(scope.row.fileUrl)"
                v-if="isExistFile(scope.row)"
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="justify-center mb-2 flex flex-row paging-container">
      <el-pagination
        layout="prev, pager, next"
        :total="exportRecord.total"
        :page-size="dataSearch.count"
        :current-page="currentPageNum"
        @current-change="currentPageChange($event)"
        background
        class="custom-pagination"
      />
    </div>
  </div>

  <template v-else>
    <ItemNotFound class="mt-56" :content="$t('empty')" />
  </template>
</template>
<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconCSV from '@/components/svg/IconCSV.vue'
import IconExcel from '@/components/svg/IconExcel.vue'
import IconPDF from '@/components/svg/IconPDF.vue'
import IconRefresh from '@/components/svg/IconRefresh.vue'
import { LOAD_BARCODE_EXPORT_LIST } from '@/store/actions'
import { BARCODE_EXPORT_FORMAT } from '@/utils/constants'
import { ClickOutside } from '@/utils/directives'
import errorHandler from '@/utils/errorHandler'
import { displayFileSize } from '@/utils/helpers'
import { EBarcodePublishStatus, EExportType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeExportResponse, IExportPaginationParams } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'

@Options({
  directives: {
    clickOutside: ClickOutside,
  },
  components: { ItemNotFound, IconCSV, IconPDF, IconExcel, IconRefresh },
  emits: ['close'],
  methods: { displayFileSize },
})
export default class BarcodeExport extends mixins(DataTableMixin) {
  loading = false
  dataSearch = { skip: 0, count: 10 } as IExportPaginationParams

  currentPageNum = 1

  fileUrl = ''

  get exportRecord() {
    return this.$store.state.barcodeExport?.[EExportType.BARCODE]
  }

  get isHaveData(): boolean {
    return !isEmpty(this.exportRecord.data)
  }

  isExistFile(data: IBarcodeExportResponse) {
    return data.status === EBarcodePublishStatus.COMPLETED && !isEmpty(data.fileUrl)
  }

  async onRefreshData() {
    this.loading = true
    await this.$store.dispatch(LOAD_BARCODE_EXPORT_LIST, this.dataSearch).finally(() => (this.loading = false))
  }

  getIcon(icon: string) {
    const iconMap: Record<string, string> = {
      [BARCODE_EXPORT_FORMAT.csv]: 'IconCSV',
      [BARCODE_EXPORT_FORMAT.pdf]: 'IconPDF',
      [BARCODE_EXPORT_FORMAT.xlsx]: 'IconExcel',
    }

    return iconMap[icon] || ''
  }

  async currentPageChange(pageNum: number) {
    this.currentPageNum = pageNum
    const skipNum = (Number(pageNum) - 1) * Number(this.dataSearch.count)
    this.dataSearch.skip = skipNum
    await this.onRefreshData()
  }

  async downloadTemplate(filePath: string) {
    try {
      this.fileUrl = filePath
      setTimeout(() => this.$refs.btnDownloadTemplate.click(), 10)
    } catch (err) {
      errorHandler(err)
    }
  }
}
</script>
