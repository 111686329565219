<template>
  <SvgBase iconName="IconCsv" :height="24" :width="21">
    <g filter="url(#filter0_d_9797_9450)">
      <path d="M17.5 19.5V7.5V6.5L12 1H0.5V19.5H17.5Z" fill="white" />
      <path
        d="M17.1527 4.60157C16.7066 3.99343 16.0843 3.28114 15.4016 2.59843C14.7189 1.91571 14.0066 1.29343 13.3984 0.847286C12.3621 0.0874285 11.8594 0 11.5714 0H1.60714C0.721286 0 0 0.721286 0 1.60714V18.9643C0 19.8501 0.721286 20.5714 1.60714 20.5714H16.3929C17.2787 20.5714 18 19.8501 18 18.9643V6.42857C18 6.14057 17.9126 5.63786 17.1527 4.60157ZM14.4913 3.50871C15.1084 4.12586 15.5919 4.68129 15.9493 5.14286H12.8559V2.05071C13.3174 2.40814 13.8741 2.89157 14.49 3.50871H14.4913ZM16.7143 18.9643C16.7143 19.1391 16.5677 19.2857 16.3929 19.2857H1.60714C1.52221 19.2847 1.44103 19.2505 1.38097 19.1905C1.3209 19.1304 1.28672 19.0492 1.28571 18.9643V1.60714C1.28571 1.43357 1.43357 1.28571 1.60714 1.28571H11.5714V5.78571C11.5714 5.95621 11.6392 6.11972 11.7597 6.24028C11.8803 6.36084 12.0438 6.42857 12.2143 6.42857H16.7143V18.9643Z"
        fill="black"
      />
    </g>
    <path
      d="M5.51107 9.69C5.49287 9.56 5.46558 9.45 5.42918 9.35C5.39279 9.25 5.3382 9.16 5.25632 9.1C5.17444 9.04 5.08346 9 4.96518 9C4.81052 9 4.69224 9.04 4.59216 9.12C4.49208 9.2 4.4193 9.31 4.37381 9.46C4.32832 9.61 4.29193 9.8 4.27373 10.03C4.25553 10.26 4.24644 10.53 4.24644 10.84C4.24644 11.17 4.24644 11.46 4.27373 11.69C4.29193 11.93 4.32832 12.12 4.37381 12.27C4.4193 12.42 4.49208 12.54 4.58306 12.61C4.67404 12.68 4.78322 12.71 4.92879 12.71C5.13805 12.71 5.29271 12.61 5.39279 12.41C5.49287 12.21 5.53836 11.89 5.53836 11.45H6.73931C6.73931 12.16 6.59374 12.7 6.3026 13.07C6.01146 13.44 5.53836 13.62 4.8651 13.62C4.54667 13.62 4.27373 13.58 4.04628 13.5C3.80973 13.42 3.61867 13.27 3.464 13.05C3.30933 12.83 3.19106 12.54 3.11827 12.18C3.03639 11.82 3 11.37 3 10.83C3 10.29 3.03639 9.82 3.13647 9.46C3.23655 9.1 3.37302 8.81 3.54588 8.59C3.71875 8.37 3.928 8.22 4.17365 8.13C4.4193 8.04 4.68314 8 4.97428 8C5.55656 8 5.99326 8.19 6.2844 8.56C6.57554 8.93 6.73021 9.44 6.73021 10.08H5.52926C5.52926 9.95 5.52926 9.82 5.51107 9.69Z"
      fill="#231815"
    />
    <path
      d="M8.30425 11.94C8.30425 12.17 8.35884 12.36 8.46802 12.5C8.57719 12.64 8.74096 12.72 8.95931 12.72C9.15037 12.72 9.30504 12.67 9.42331 12.56C9.54159 12.45 9.59618 12.29 9.59618 12.08C9.59618 11.91 9.55069 11.77 9.45971 11.68C9.36873 11.59 9.25955 11.52 9.14127 11.47L8.25876 11.12C7.91303 10.99 7.64919 10.8 7.47633 10.56C7.30346 10.32 7.21248 10 7.21248 9.62C7.21248 9.4 7.24887 9.19 7.31256 9C7.37625 8.81 7.48542 8.63 7.63099 8.48C7.77656 8.33 7.96762 8.22 8.19507 8.13C8.42253 8.04 8.69547 8 9.0139 8C9.58708 8 10.0056 8.13 10.2785 8.4C10.5515 8.67 10.6879 9.04 10.6879 9.53V9.75H9.55978C9.55978 9.47 9.52339 9.27 9.43241 9.14C9.35053 9.01 9.20496 8.94 9.0048 8.94C8.85014 8.94 8.70457 8.99 8.58629 9.09C8.46802 9.19 8.40433 9.33 8.40433 9.53C8.40433 9.66 8.44072 9.78 8.51351 9.89C8.58629 10 8.73186 10.09 8.95021 10.16L9.70535 10.44C10.0966 10.59 10.3786 10.78 10.5424 11.01C10.7061 11.25 10.788 11.57 10.788 11.98C10.788 12.27 10.7425 12.51 10.6516 12.72C10.5606 12.93 10.4332 13.11 10.2694 13.25C10.1057 13.39 9.91461 13.49 9.69626 13.55C9.4779 13.61 9.23225 13.64 8.95931 13.64C8.60449 13.64 8.31335 13.6 8.0859 13.53C7.84935 13.46 7.66739 13.35 7.53091 13.2C7.39444 13.05 7.30346 12.88 7.24887 12.66C7.19429 12.45 7.16699 12.22 7.16699 11.96V11.77H8.29515V11.95L8.30425 11.94Z"
      fill="#231815"
    />
    <path
      d="M15.0003 8.14062L13.7721 13.5206H12.3164L11.0518 8.14062H12.3619L13.0624 11.9906H13.0806L13.7448 8.14062H15.0003Z"
      fill="#231815"
    />
    <defs>
      <filter
        id="filter0_d_9797_9450"
        x="0"
        y="0"
        width="20.5"
        height="23.0723"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.5" dy="1.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9797_9450" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9797_9450" result="shape" />
      </filter>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconCsv extends Vue {}
</script>
