import en from '@/i18n/en.json'
import ja from '@/i18n/ja.json'
import zhcn from '@/i18n/zh-cn.json'
import zhtw from '@/i18n/zh-tw.json'
import { LOCAL_STORAGE_ITEM } from '@/utils/constants'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: localStorage.getItem(LOCAL_STORAGE_ITEM.LOCALE) || navigator.language.toLowerCase(),
  fallbackLocale: 'ja-jp',
  messages: {
    'ja-jp': ja,
    en,
    ja,
    'en-us': en,
    'zh-tw': zhtw,
    'zh-cn': zhcn,
  },
})

export default i18n
