<template>
  <SvgBase name="MenuProduct" width="14" height="18">
    <rect x="0.5" y="1.5" width="13" height="16" rx="1.5" fill="none" />
    <rect x="3.5" y="0.5" width="7" height="2" rx="1" fill="none" />
    <line x1="5.5" y1="6.5" x2="10.5" y2="6.5" stroke-linecap="round" fill="none" />
    <circle cx="3.5" cy="6.5" r="0.5" fill="none" />
    <line x1="5.5" y1="8.5" x2="10.5" y2="8.5" stroke-linecap="round" fill="none" />
    <circle cx="3.5" cy="8.5" r="0.5" fill="none" />
    <line x1="5.5" y1="10.5" x2="10.5" y2="10.5" stroke-linecap="round" fill="none" />
    <circle cx="3.5" cy="10.5" r="0.5" fill="none" />
    <line x1="5.5" y1="12.5" x2="10.5" y2="12.5" stroke-linecap="round" fill="none" />
    <circle cx="3.5" cy="12.5" r="0.5" fill="none" />
    <line x1="5.5" y1="14.5" x2="10.5" y2="14.5" stroke-linecap="round" fill="none" />
    <circle cx="3.5" cy="14.5" r="0.5" fill="none" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMenuProductList extends Vue {}
</script>
