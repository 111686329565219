<template>
  <SvgBase
    iconName="VerticalThreeDots"
    width="4"
    height="18"
    viewBox="0 0 4 18"
  >
    <svg fill="none">
      <path
        d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4Z"
        fill="#979797"
      />
      <path
        d="M2 10.9331C3.10457 10.9331 4 10.0377 4 8.93311C4 7.82854 3.10457 6.93311 2 6.93311C0.895431 6.93311 0 7.82854 0 8.93311C0 10.0377 0.895431 10.9331 2 10.9331Z"
        fill="#979797"
      />
      <path
        d="M2 17.7998C3.10457 17.7998 4 16.9044 4 15.7998C4 14.6952 3.10457 13.7998 2 13.7998C0.895431 13.7998 0 14.6952 0 15.7998C0 16.9044 0.895431 17.7998 2 17.7998Z"
        fill="#979797"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconVerticalThreeDots extends Vue {}
</script>
