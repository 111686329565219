import { createStore } from 'vuex'
import {
  CLOSE_DIALOG,
  OPEN_DIALOG,
  RESET_BACK_TO_LOGIN,
  RESET_PAGE_NOT_FOUND,
  SET_BACK_TO_LOGIN,
  SET_PAGE_NOT_FOUND,
} from './actions'
import Address from './address'
import Barcode from './barcode'
import BarcodeExport from './barcode-export'
import BarcodeImport from './barcode-import'
import Carrier from './carrier'
import Client from './client'
import Enterprise from './enterprise'
import Location from './location'
import Permission from './permission-dialog'
import Product from './product'
import ProfileModule from './profile'
import Project from './project'
import Statistics from './statistics'
import TimeTable from './timetable'
import UserGroup from './user-group'
import TradeWaltz from '@/views/tradeWaltz/tradeWaltzStore'

interface TAppState {
  dialogVisible: boolean
  dialogConfig: {
    title: string
    message: string
    confirmation: boolean
    needtranslation?: boolean
  }
  isNotFound: boolean
  notFoundItem: string
  isBackToLogin: boolean
  errorCode: string
}

const getDefaultDialogConfig = () => {
  return {
    title: '',
    message: '',
    confirmation: true,
    needtranslation: false,
  }
}

export default createStore({
  strict: true,
  state: {
    dialogVisible: false,
    dialogConfig: {
      title: '',
      message: '',
      confirmation: true,
    },
    isNotFound: false,
    notFoundItem: '',
    isBackToLogin: false,
    errorCode: '',
  },
  mutations: {
    [OPEN_DIALOG]: (
      state: TAppState,
      payload: {
        title: string
        message: string
        confirmation: boolean
      }
    ) => {
      state.dialogVisible = true
      state.dialogConfig = payload
    },
    [CLOSE_DIALOG]: (state: TAppState) => {
      state.dialogVisible = false
      state.dialogConfig = getDefaultDialogConfig()
    },
    [SET_PAGE_NOT_FOUND]: (state: TAppState, payload?: { item: string }) => {
      state.isNotFound = true
      state.notFoundItem = payload?.item || ''
    },
    [RESET_PAGE_NOT_FOUND]: (state: TAppState) => {
      state.isNotFound = false
      state.notFoundItem = ''
    },
    [SET_BACK_TO_LOGIN]: (state: TAppState, payload?: { errorCode: string }) => {
      state.isBackToLogin = true
      state.errorCode = payload?.errorCode ?? ''
    },

    [RESET_BACK_TO_LOGIN]: (state: TAppState) => {
      state.isBackToLogin = false
      state.errorCode = ''
    },
  },
  actions: {
    [OPEN_DIALOG]: (
      {
        commit,
      }: {
        commit: Function
      },
      payload: {
        title?: string
        message: string
        confirmation?: boolean
        needtranslation?: boolean
      }
    ) => {
      commit(OPEN_DIALOG, { ...getDefaultDialogConfig(), ...payload })
    },
    [CLOSE_DIALOG]: ({ commit }: { commit: Function }) => {
      commit(CLOSE_DIALOG)
    },
    [SET_PAGE_NOT_FOUND]: (
      {
        commit,
      }: {
        commit: Function
      },
      payload: {
        item: string
      }
    ) => {
      commit(SET_PAGE_NOT_FOUND, payload)
    },
    [RESET_PAGE_NOT_FOUND]: ({ commit }: { commit: Function }) => {
      commit(RESET_PAGE_NOT_FOUND)
    },

    [SET_BACK_TO_LOGIN]: (
      {
        commit,
      }: {
        commit: Function
      },
      payload: {
        errorCode: string
      }
    ) => {
      commit(SET_BACK_TO_LOGIN, payload)
    },
    [RESET_BACK_TO_LOGIN]: ({ commit }: { commit: Function }) => {
      commit(RESET_BACK_TO_LOGIN)
    },
  },
  modules: {
    profile: ProfileModule,
    timetable: TimeTable,
    project: Project,
    carrier: Carrier,
    client: Client,
    location: Location,
    barcode: Barcode,
    enterprise: Enterprise,
    address: Address,
    product: Product,
    permission: Permission,
    barcodeImport: BarcodeImport,
    barcodeExport: BarcodeExport,
    statistics: Statistics,
    userGroup: UserGroup,
    tradeWaltz: TradeWaltz,
  },
})
