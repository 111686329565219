import { ElMessage } from 'element-plus'
import moment from 'moment'
import { IMessageType, ITrackPointKeyVal, TreeNode } from 'smartbarcode-web-core/src/utils/types/index'
import { EEncodingType, ELanguageLocale } from 'smartbarcode-web-core/src/utils/enums/index'
import i18n from '@/i18n/index'

/* eslint-disable no-prototype-builtins */
export function uuidv4() {
  return 'xxx-yxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * @param {string} quickCriteria Any string value to search for in the object properties.
 * @param {any[]} objectArray The array of objects as the search domain
 * @return {any[]} the search result
 */
export function search(quickCriteria: string, objectArray: object[]) {
  const result = objectArray.filter((obj) =>
    Object.values(obj).some((val) =>
      val
        ? val
            .toString()
            .toLowerCase()
            .includes(quickCriteria)
        : false
    )
  )
  return result
}

const maxMessage = 3
const messageArr: string[] = []

export function openMessage(message: string, type: IMessageType) {
  if (messageArr.length === maxMessage) return false
  messageArr.push(message)
  ElMessage({
    showClose: true,
    center: true,
    message: message,
    type: type,
    // offset: type === 'error' ? 150 : 100,
    onClose: () => {
      messageArr.shift()
    },
  })
}

export function sortTrackingPoints(trackPointArr: ITrackPointKeyVal[]) {
  trackPointArr.sort((a, b) => {
    return a.value.order && b.value.order ? a.value.order - b.value.order : Number(a.key) - Number(b.key)
  })
}

export function isAfterDate(date: Date) {
  if (moment().isAfter(date)) {
    return false
  }
  return true
}

export function GetEncoding(encoding: string): string {
  switch (encoding) {
    case null:
    case EEncodingType.UTF_8:
      return EEncodingType.UTF_8
    case EEncodingType.SHIFT_JIS:
      return 'Shift_JIS'
    case EEncodingType.BIG_5:
      return EEncodingType.BIG_5
    case EEncodingType.GB_2312:
      return EEncodingType.GB_2312
    default:
      return EEncodingType.UTF_8
  }
}

function isEnglish() {
  return i18n.global.locale.split('-')[0] === ELanguageLocale.EN
}

function isChinese() {
  return i18n.global.locale.split('-')[0] === ELanguageLocale.ZH
}

function isJapanasese() {
  return i18n.global.locale.split('-')[0] === ELanguageLocale.JA
}

export function isLocaleLanguage(lang: ELanguageLocale) {
  switch (lang) {
    case ELanguageLocale.EN:
      return isEnglish()
    case ELanguageLocale.ZH:
      return isChinese()
    case ELanguageLocale.JA:
      return isJapanasese()
    default:
      break
  }
}

export function compareDates(a: string, b: string) {
  const [aYear, aMonth] = a.split('-').map(Number)
  const [bYear, bMonth] = b.split('-').map(Number)

  // Compare years
  if (aYear !== bYear) {
    return aYear - bYear
  }

  // If years are the same, compare months
  return aMonth - bMonth
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function buildTree(data: any, keyPath = ''): TreeNode[] {
  const nodes: TreeNode[] = []

  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      const currentKeyPath = keyPath ? `${keyPath}.${key}` : key

      if (typeof data[key] === 'object' && data[key] !== null) {
        const node: TreeNode = { id: nodes.length + 2, label: key, keyPath: currentKeyPath }
        node.children = buildTree(data[key], currentKeyPath)
        nodes.push(node)
      } else {
        nodes.push({ id: nodes.length + 2, label: key, keyPath: currentKeyPath })
      }
    }
  }

  return nodes
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseObjectToTree(data: any): TreeNode[] {
  // const rootNode: TreeNode = { id: 1, label: 'Data', keyPath: 'Data' } // Assuming root node ID is always 1
  // rootNode.children = buildTree(data)

  const rootNode: TreeNode[] = [...buildTree(data)]
  return rootNode
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function treeToPlainObject(tree: TreeNode): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const node: any = { label: tree.label }
  if (tree.children && tree.children.length > 0) {
    node.children = tree.children.map((child) => treeToPlainObject(child))
  }
  return node
}

export function reverseLoopKeyPath(keyPath: string, callback: (currentPath: string, iteration: number) => void): void {
  const segments = keyPath.split('.')
  let currentPath = ''

  for (let i = 0; i < segments.length; i++) {
    if (i !== 0) {
      currentPath = segments.slice(0, segments.length - i).join('.')
      callback(currentPath, segments.length - i)
    } else {
      callback(keyPath, segments.length - 1)
    }
  }
}

export const DUMMY_GOODS_STRUCTURE = Object.freeze({
  keyGrp: {
    goodsLineId: '',
    goodsLineSeq: null,
  },
  goodsGrp: {
    goodsId: '',
    goodsShortName: '',
    goodsNameEn: '',
    goodsRefNo1: null,
    goodsRefNo2: null,
    productSpec: null,
    countryOfOriginCode: null,
    countryOfOriginName: null,
    exportLicence: null,
    exportLicenceNo: null,
    importLicence: null,
    importLicenceNo: null,
    goodsType: '',
    prevGoodsLineId: '',
    prevGoodsLineSeq: 1,
    originGoodsLineId: '',
    originGoodsLineSeq: 1,
    countryOfOriginCodeArray: [],
  },
  referenceNoGrp: {
    poNo: null,
    contractNo: null,
    exportInvoiceNo: null,
    bookingNo: null,
    blNo: null,
    commercialInvoiceNo: null,
    importInvoiceNo: null,
  },
  quantityGrp: {
    quantity1: null,
    quantity2: null,
    unitId1: '',
    unitId2: '',
    unit1: '',
    unit2: null,
    unitRoundType1: null,
    unitRoundType2: null,
    unitId1Array: [],
    unitId2Array: [],
  },
  remarksGrp: {
    remarks1: null,
    remarks2: null,
    remarks3: null,
    remarks4: null,
    remarks5: null,
    remarks6: null,
    remarks7: null,
    remarks8: null,
    remarks9: null,
    remarks10: null,
  },
})
