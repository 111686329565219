<template>
  <SvgBase iconName="IconArrowLeft" :width="20" :height="18">
    <g fill="none">
      <path
        d="M0.422138 9.83324L10.1948 17.8564L11.7029 15.9864L4.58455 10.1349L20 10.1349V7.72187L4.58455 7.72187L11.7029 1.87037L10.1948 0.000295639L0.482464 8.0235C0.18084 8.2648 0.0601902 8.56642 0.0601902 8.92837C0.0601902 9.29032 0.18084 9.65227 0.422138 9.83324Z"
        :fill="color"
      />
    </g>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconArrowLeft extends Vue {}
</script>
