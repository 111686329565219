<template>
  <SvgBase iconName="Dashboard" :width="26" :height="24">
    <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5793_7102)">
        <path
          d="M5.98568 20.0092C4.59815 18.6223 3.65308 16.855 3.27002 14.931C2.88695 13.0069 3.08309 11.0124 3.83364 9.19987C4.58419 7.3873 5.85543 5.83803 7.48656 4.748C9.1177 3.65798 11.0354 3.07617 12.9973 3.07617C14.9591 3.07617 16.8768 3.65798 18.508 4.748C20.1391 5.83803 21.4103 7.3873 22.1609 9.19987C22.9114 11.0124 23.1076 13.0069 22.7245 14.931C22.3414 16.855 21.3964 18.6223 20.0088 20.0092H5.98568Z"
          stroke-width="2"
        />
        <path d="M5.18359 13.2305H7.63467" stroke-width="2" />
        <path d="M20.8173 13.2305H18.3662" stroke-width="2" />
        <path d="M18.5282 7.70508L16.793 9.43493" stroke-width="2" />
        <path d="M12.9971 5.41211V7.86318" stroke-width="2" />
        <path d="M7.47168 7.70508L12.9649 13.1929" stroke-width="2" />
        <path
          d="M13.0783 15.4534C14.39 15.4534 15.4534 14.39 15.4534 13.0783C15.4534 11.7665 14.39 10.7031 13.0783 10.7031C11.7665 10.7031 10.7031 11.7665 10.7031 13.0783C10.7031 14.39 11.7665 15.4534 13.0783 15.4534Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_5793_7102">
          <rect width="22" height="19.0934" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconDashboard extends Vue {}
</script>
