<template>
  <div class="notfound-view">
    <img alt="SmartBarcode logo" src="@/assets/logo-smartBarcode.png" class="app-logo" />
    <h1>404</h1>
    <h2>{{ $t('Not Found', { item: $t(item) }) }}</h2>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  name: 'NotFound',
})
export default class NotFound extends Vue {
  @Prop({ type: String }) readonly item: string = 'item'
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 42px;
  margin-bottom: 16px;
}

.notfound-view {
  text-align: center;
  padding-top: 100px;
  img {
    width: 400px;
    max-width: 100%;
  }
}

.app-logo {
  padding: 24px 0;
  width: 100%;
}
</style>
