<template>
  <div class="custom-dialog">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="_isShow"
      top="0"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="closeDialog"
    >
      <template #title>
        <div class="flex justify-center">{{ $t('account_switching') }}</div>
      </template>
      <div class="h-full">
        <div class="flex justify-center">{{ $t('account_switching_description_msg') }}</div>
        <div class="flex justify-end items-center mx-4 my-6 gap-2">
          <span>{{ $t('company_selection') }}</span>
          <el-select class="w-44" v-model="companySelected" @change="onImpersonatableOrgChanged">
            <el-option
              v-for="item in impersonatables"
              :key="item.organizationId"
              :label="item.organizationName"
              :value="item.organizationId"
            >
              {{ item.organizationName }}
            </el-option>
          </el-select>
        </div>
        <div>
          <div class="mt-2 flex-grow table-container">
            <el-table
              :data="displayingUsers"
              class="rounded-xl w-full"
              header-cell-class-name="custom-table__header-cell"
              cell-class-name="custom-table__cell cursor-pointer"
              :empty-text="$t('empty')"
              highlight-current-row
              border
              @current-change="onRowClick"
            >
              <el-table-column prop="userName" :label="$t('full_name')" sortable />
              <el-table-column :label="$t('enterprise.role')" sortable>
                <template v-slot="scope">
                  {{ scope.row.roleType ? $t(`roleType.${scope.row.roleType}`) : '' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="justify-center flex flex-row my-6 paging-container">
          <el-pagination
            layout="prev, pager, next"
            :total="impersonatableUsers.length"
            :page-size="itemsPerPage"
            :current-page="curPageNum"
            @current-change="curPageNum = $event"
            background
            class="custom-pagination"
          />
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { IMPERSONATE } from '@/store/actions'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IImpersonatable } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Emit, PropSync } from 'vue-property-decorator'
export interface IOrganization {
  organizationId: string
  organizationName: string
}
export interface IUser {
  userId: string
  userName: string
  roleType: string
}
@Options({
  emits: ['update:isShow'],
  components: {},
  name: 'AccountSwitchingdialog',
})
export default class AccountSwitchingDialog extends Vue {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean

  impersonatables = [] as IImpersonatable[]
  impersonatableUsers = [] as IUser[]

  companySelected = ''

  itemsPerPage = 9
  curPageNum = 1

  get displayingUsers() {
    const from = (this.curPageNum - 1) * this.itemsPerPage
    const to = this.curPageNum * this.itemsPerPage
    return this.impersonatableUsers.filter((item, idx) => idx >= from && idx < to)
  }

  onRowClick(user: IUser) {
    this.$store.dispatch(IMPERSONATE, { id: user.userId }).then(() => (window.location.href = '/'))
    this.closeDialog()
  }

  onImpersonatableOrgChanged(orgId: string) {
    const selectedOrg = this.impersonatables.find((i) => i.organizationId === orgId)
    this.companySelected = selectedOrg?.organizationId ?? ''
    this.impersonatableUsers = (selectedOrg?.users ?? []) as IUser[]
  }

  created() {
    this.impersonatables = (this.$store.state.profile.impersonatables ?? []) as IImpersonatable[]
    if (!isEmpty(this.impersonatables)) this.onImpersonatableOrgChanged(this.impersonatables[0].organizationId)
  }

  @Emit('update:isShow')
  closeDialog() {
    this._isShow = false
  }
}
</script>
<style lang="scss" scoped>
.custom-dialog:deep() {
  &.el-overlay {
    .el-dialog {
      &.el-dialog--custom {
        max-width: 855px !important;
        height: 765px !important;
        margin-top: 0px !important;
        .el-dialog__body {
          height: 100%;
        }
      }
    }
  }
}
</style>
