<template>
  <SvgBase iconName="MenuCarrier" :width="19" :height="21">
    <path
      d="M11.6001 10.479L10.181 11.0678L11.6748 11.427C15.6174 12.375 18.5 15.9017 18.5 20.1985V20.5H0.5V20.1985C0.5 15.8994 3.34292 12.3751 7.32414 11.4272L8.82446 11.0701L7.39995 10.479C5.44176 9.66654 4.08333 7.83252 4.08333 5.69084C4.08333 2.85694 6.46829 0.5 9.5 0.5C11.7202 0.5 13.6246 1.78748 14.4604 3.65117L14.5929 3.94656H14.9167H16.8333V4.54962H15.375H14.7603L14.8855 5.15141C14.9148 5.29249 14.9167 5.43989 14.9167 5.69084C14.9167 7.83252 13.5582 9.66654 11.6001 10.479ZM13.0417 3.94656H13.9772L13.4574 3.16873C12.6134 1.90593 11.1647 1.10305 9.5 1.10305C7.83525 1.10305 6.38664 1.90593 5.54263 3.16873L5.02275 3.94656H5.95833H13.0417ZM5.29167 4.54962H4.79167V5.04962C4.79167 5.1201 4.78438 5.19373 4.77327 5.30591L4.77327 5.30593C4.76287 5.41094 4.75 5.54326 4.75 5.69084C4.75 8.26949 6.86738 10.2786 9.5 10.2786C12.091 10.2786 14.25 8.22942 14.25 5.69084L14.25 5.67143C14.2501 5.48331 14.2502 5.19939 14.1979 4.94783L14.1151 4.54962H13.7083H5.29167ZM17.2917 19.8969H17.8391L17.7896 19.3517C17.3916 14.9704 13.9711 11.7634 9.5 11.7634C5.02692 11.7634 1.60845 15.0124 1.21042 19.3513L1.70833 19.3969V19.8969H17.2917Z"
      fill="#C4C4C4"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUser extends Vue {}
</script>
