<template>
  <SvgBase iconName="IconUnknow" :height="24" :width="21">
    <g filter="url(#filter0_d_10556_7947)">
      <path d="M17.5 19.5V7.5V6.5L12 1H0.5V19.5H17.5Z" fill="white" />
      <path
        d="M17.1527 4.60157C16.7066 3.99343 16.0843 3.28114 15.4016 2.59843C14.7189 1.91571 14.0066 1.29343 13.3984 0.847286C12.3621 0.0874285 11.8594 0 11.5714 0H1.60714C0.721286 0 0 0.721286 0 1.60714V18.9643C0 19.8501 0.721286 20.5714 1.60714 20.5714H16.3929C17.2787 20.5714 18 19.8501 18 18.9643V6.42857C18 6.14057 17.9126 5.63786 17.1527 4.60157ZM14.4913 3.50871C15.1084 4.12586 15.5919 4.68129 15.9493 5.14286H12.8559V2.05071C13.3174 2.40814 13.8741 2.89157 14.49 3.50871H14.4913ZM16.7143 18.9643C16.7143 19.1391 16.5677 19.2857 16.3929 19.2857H1.60714C1.52221 19.2847 1.44103 19.2505 1.38097 19.1905C1.3209 19.1304 1.28672 19.0492 1.28571 18.9643V1.60714C1.28571 1.43357 1.43357 1.28571 1.60714 1.28571H11.5714V5.78571C11.5714 5.95621 11.6392 6.11972 11.7597 6.24028C11.8803 6.36084 12.0438 6.42857 12.2143 6.42857H16.7143V18.9643Z"
        fill="black"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_10556_7947"
        x="0"
        y="0"
        width="20.5"
        height="23.0703"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.5" dy="1.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10556_7947" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10556_7947" result="shape" />
      </filter>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUnknow extends Vue {}
</script>
