import { Vue as VueIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import moment from 'moment-timezone'
import 'reset-css'
import { createApp } from 'vue'
import vueDebounce from 'vue-debounce'
import VueGtag from 'vue-gtag'
import LeaderLine from 'vue3-leaderline'
import Vue3SimpleHtml2pdf from 'vue3-simple-html2pdf'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './views/router-hooks'
import '@/assets/css/tailwind.scss'
import i18n from '@/i18n'
moment.locale(i18n.global.locale)

Sentry.init({
  dsn: 'https://a8701f6131ab49e6a0914964e6a6efc9@o469851.ingest.sentry.io/5678831',
  release: 'smartbarcode@' + process.env.npm_package_version,
  integrations: [
    new VueIntegration({
      tracing: true,
    }),
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
  environment: process.env.VUE_APP_ENV,
})

// disable auto position leader line when windows resize
LeaderLine.positionByWindowResize = false

createApp(App)
  .use(store)
  .use(router)
  .use(
    VueGtag,
    {
      appName: 'SmartBarcode-Portal',
      pageTrackerScreenviewEnabled: true,
      config: {
        id: process.env.VUE_APP_GA4_MEASUREMENT_ID,
      },
    },
    router
  )
  .use(i18n)
  .use(Vue3SimpleHtml2pdf)
  .use(ElementPlus, {
    i18n: (key: string) => {
      return i18n.global.t(key)
    },
  })
  .use(vueDebounce, { defaultTime: '400ms' })
  .mount('#app')
