// App
export const OPEN_DIALOG = 'app/openDialog'
export const CLOSE_DIALOG = 'app/closeDialog'
export const REGISTER_TRANSLATE = 'app/registerTranslate'
export const SET_PAGE_NOT_FOUND = 'app/setPage404'
export const RESET_PAGE_NOT_FOUND = 'app/resetPage404'
export const SET_LOADING = 'app/loading'
export const CLEAR_LOADING = 'app/clearLoading'
export const SET_BACK_TO_LOGIN = 'app/setBackToLogin'
export const RESET_BACK_TO_LOGIN = 'app/resetBackToLogin'

// Profile
export const SAVE_PROFILE = 'profile/saveProfile'
export const FETCH_PROFILE = 'profile/fetchProfile'
export const LOGOUT = 'profile/logout'
export const LOGIN = 'profile/login'
export const CLEAR_PROFILE = 'profile/clearProfile'
export const CLEAR_ALL = 'profile/clearAll'
export const FETCH_USER_LIST = 'profile/fetchUserList'
export const SAVE_USER_LIST = 'profile/saveUserList'
export const IMPERSONATE = 'profile/impersonate'
export const UNIMPERSONATE = 'profile/unimpersonate'
export const SAVE_WHITE_IP_LIST = 'profile/saveWhiteIPList'

// refs
export const FETCH_COUNTRIES = 'user/contries'
export const SAVE_COUNTRIES = 'user/contries'
export const FETCH_ADDRESSES = 'user/address'
export const FETCH_PRODUCTS = 'barcode/product'
export const FETCH_PRODUCT_SUPPLIERS = 'barcode/product-suppliers'

// Timetable
export const IMPORT_TIMETABLE_CSV = 'timetable/importCSV'
export const LOAD_TIMETABLE = 'timetable/fetchTimetable'
export const LOAD_TIMETABLE_SCHEDULE = 'timetable/fetchTimetableSchedule'
export const LOAD_TIMETABLE_SCHEDULE_SUCCESS = 'timetable/fetchTimetableScheduleSuccess'
export const DELETE_TIME_TABLE = 'timetable/delete'
export const ACKNOWNLEDGE_TIMETABLE = 'timetable/acknowledge'
export const HEADER_BUTTON_CLICKED = 'timetable/headerButtonClicked'
export const HEADER_BUTTON_DATA_CLEAR = 'timetable/headerButtonClear'
export const UPDATE_TIMETABLE_TIME = 'timetable/updateTimeValue'
export const TIMETABLE_IS_EDIT_TIME = 'timetable/isEditMode'

// Project
export const LOAD_PROJECTS_LIST = 'project/fetchProjectList'
export const LOAD_ACTIVE_PROJECTS_LIST = 'project/fetchActiveProjectList'
export const LOAD_CURRENT_USER_PROJECTS_LIST = 'project/fetchCurrentUserProjectList'
export const CLEAR_LOAD_PROJECTS_LIST = 'project/clearFetchProjectList'
export const FETCH_PROJECT = 'project/fetchProject'
export const SAVE_PROJECT = 'project/saveProject'
export const UPDATE_ORIGIN_PROJECT_DETAIL = 'project/updateOriginalProject'
export const UPDATE_TRACKING_POINTS = 'project/updateTrackingPoint'
export const UPDATE_BARCODE_TYPES = 'project/updateBarcodeTypes'
export const CLEAR_DETAIL_PROJECT = 'project/clearDetailProject'
export const SET_PROJECT_READ_ONLY = 'project/setProjectReadOnly'
export const INIT_PROJECT_DETAIL = 'project/initProjectDetail'
export const FETCH_USER_GROUP_STAFF = 'proejct/fecthUserGroupStaff'

// Carrier
export const FETCH_STAFF = 'carrier/fetchSaff'
export const IS_ACTIVE_CARRIER = 'user/isActiveCarrier'
export const IS_ACTIVE_USER_GROUP = 'user/isActiveUserGroup'
export const FETCH_USER_GROUPS = 'carrier/fetchUserGroups'
export const FETCH_USER_GROUP = 'carrier/fetchUserGroup'
export const FETCH_USER_GROUP_ADD = 'carrier/fetchUserGroupStaff'
export const FETCH_USER_GROUP_PROJECTS = 'carrier/fetchProjects'
export const UPDATE_USER_GROUP_PERMISSION = 'carrier/updateUserGroupPermission'
export const DELETE_USER_GROUP_PERMISSION = 'carrier/deleteUserGroupPermission'
export const UPDATE_USER_LIST = 'carrier/updateUserList'
// client
export const IS_ACTIVE_CLIENT = 'user/isActiveClient'
export const LOAD_CLIENT_LIST = 'user/fetchClientList'
export const FETCH_CLIENT = 'project/fetchClient'
export const SAVE_CLIENT = 'user/saveClient'
export const CLEAR_DETAIL_CLIENT = 'user/clearDetailClient'

// staff
export const LOAD_ENTERPRISE_LIST = 'user/fetchStaffList'
export const FETCH_ENTERPRISE = 'project/fetchEnterprise'
export const SAVE_ENTERPRISE = 'user/saveStaff'
export const CLEAR_DETAIL_ENTERPRISE = 'user/clearDetailStaff'

// location
export const LOAD_LOCATION_LIST = 'location/fetchLocationList'
export const FETCH_LOCATION = 'location/fetchLocation'
export const LOAD_LOCATION_PERMISSIONS = 'location/fetchLocationPermissions'
export const SAVE_LOCATION = 'location/saveLocation'
export const CLEAR_DETAIL_LOCATION = 'location/clearDetailLocation'
export const IS_ACTIVE_LOCATION = 'location/isActiveLocation'

// Address
export const SAVE_ADDRESSES = 'address/saveAddress'

// Product
export const SAVE_PRODUCT = 'product/saveProduct'

// barcode
export const LOAD_BARCODE_LIST = 'user/fetchBarcodeList'
export const FETCH_BARCODE = 'project/fetchBarcode'
export const SAVE_BARCODE = 'user/saveBarcode'
export const CLEAR_DETAIL_BARCODE = 'user/clearDetailBarcode'
export const SET_BARCODE_LOADING = 'app/barcodeLoading'
export const CLEAR_BARCODE_LOADING = 'app/clearBarcodeLoading'
export const FETCH_BARCODE_CHILDREN = 'project/fetchBarcodeChildren'
export const UPDATE_BARCODE_LIST_COUNT = 'barcode/updateBarcodeListCount'

// barcodeImport
export const LOAD_BARCODE_IMPORT_LIST = 'barcode/barcodeImport'
export const SET_BARCODE_IMPORT_LOADING = 'barcode/barcodeImportLoading'
export const CLEAR_BARCODE_IMPORT_LOADING = 'barcode/clearBarcodeImportLoading'
export const LOAD_BARCODE_IMPORT_PROJECT = 'barcode/barcodeImportProject'

// Permission Dialog
export const SAVE_PERMISSION = 'common/permissionDialog'

// Stats
export const FETCH_STATISTICS = 'statistics/barcode'

// barcodeExport
export const LOAD_EXPORT_LIST = 'loadExportList'
export const LOAD_BARCODE_EXPORT_LIST = 'barcode/barcodeExport'
export const LOAD_ACESS_LOG_EXPORT_LIST = 'barcode/accessLogExport'
export const LOAD_LOCATION_EXPORT_LIST = 'location/loadLocationExport'
export const LOAD_BARCODE_ARCHIVE_LIST = 'barcode/barcodeArchieve'
export const SET_HAVE_NEW_EXPORT_RECORD = 'haveNewExportRecord'

// TradeWaltzStoreDialog
export const SET_DISPLAY_PAGE = 'tradeWaltz/setPage'
export const SAVE_TW_BINDING_SETTING = 'tradeWaltz/savePOBindingSetting'
export const FETCH_ALL_TW_PROCESS = 'tradeWaltz/fetchAllTWProcess'
export const SAVE_TW_PROCESS = 'tradeWaltz/saveTWProcess'
