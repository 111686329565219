/* eslint-disable @typescript-eslint/no-explicit-any */
const ClickOutside = {
  beforeMount: function(el: any, binding: any) {
    const ourClickEventHandler = (event: { target: any }) => {
      if (!el.contains(event.target) && el !== event.target) {
        binding.value(event) // before binding it
      }
    }
    el.__vueClickEventHandler__ = ourClickEventHandler
    document.addEventListener('click', ourClickEventHandler)
  },
  unmounted: function(el: { __vueClickEventHandler__: (this: Document, ev: MouseEvent) => any }) {
    document.removeEventListener('click', el.__vueClickEventHandler__)
  },
}

export { ClickOutside }
