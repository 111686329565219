import store from '@/store'
import { ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '@/views/ForgotPassword.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/new-password',
    name: 'new-password',
    component: () => import(/* webpackChunkName: "new-password-set" */ '@/views/ForgotPassword.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: {
      restricted: true,
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Dashboard.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.DASHBOARD,
    },
  },
  {
    path: '/timetable',
    name: 'timetable',
    component: () => import('@/views/TimeTable.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.TIMETABLE,
    },
    beforeEnter: (to, from, next) => {
      store.getters.isEnableTimetable
        ? next()
        : next({
            name: 'home',
          })
    },
  },
  {
    path: '/project',
    name: 'projects',
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.PROJECT,
    },
  },
  {
    path: '/project/create',
    name: 'projectCreate',
    component: () => import(/* webpackChunkName: "projectCreate" */ '@/views/ProjectForm.vue'),
    props: true,
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.PROJECT,
    },
  },
  {
    path: '/project/edit/:code',
    name: 'projectEdit',
    component: () => import(/* webpackChunkName: "projectEdit" */ '@/views/ProjectForm.vue'),
    props: true,
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.PROJECT,
    },
  },
  {
    path: '/project-draft/edit/:code',
    name: 'projectDraft',
    component: () => import(/* webpackChunkName: "projectEdit" */ '@/views/ProjectForm.vue'),
    props: true,
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.PROJECT,
    },
  },
  {
    path: '/location',
    name: 'location',
    component: () => import(/* webpackChunkName: "location" */ '@/views/Location.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.LOCATION,
    },
  },
  {
    path: '/location/create',
    name: 'locationCreate',
    component: () => import(/* webpackChunkName: "locationCreate" */ '@/views/LocationDetail.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.LOCATION,
    },
  },
  {
    path: '/location/edit/:id',
    name: 'locationEdit',
    component: () => import(/* webpackChunkName: "locationEdit" */ '@/views/LocationDetail.vue'),
    props: true,
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.LOCATION,
    },
  },
  {
    path: '/client',
    name: 'clients',
    component: () => import(/* webpackChunkName: "client" */ '@/views/Client.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CLIENT,
    },
  },
  {
    path: '/client/create',
    name: 'clientCreate',
    component: () => import(/* webpackChunkName: "clientCreate" */ '@/views/ClientForm.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CLIENT,
    },
  },
  {
    path: '/client/edit/:id',
    name: 'clientEdit',
    component: () => import(/* webpackChunkName: "clientEdit" */ '@/views/ClientForm.vue'),
    props: true,
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CLIENT,
    },
  },
  {
    path: '/carrier',
    name: 'carriers',
    component: () => import(/* webpackChunkName: "carrier" */ '@/views/Carrier.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CARRIER,
    },
  },
  {
    path: '/carrier/create',
    name: 'carrierCreate',
    props: true,
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CARRIER,
    },
    component: () => import(/* webpackChunkName: "projectCreate" */ '@/components/carrier/CarrierForm.vue'),
  },
  {
    path: '/carrier/edit/:id',
    name: 'carrierEdit',
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CARRIER,
    },
    component: () => import(/* webpackChunkName: "projectEdit" */ '@/components/carrier/CarrierForm.vue'),
  },
  {
    path: '/user-group',
    name: 'userGroup',
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CARRIER,
    },
    component: () => import(/* webpackChunkName: "projectEdit" */ '@/components/carrier/UserGroup.vue'),
    props: true,
  },
  {
    path: '/user-group/add',
    name: 'userGroupCreate',
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CARRIER,
    },
    component: () => import(/* webpackChunkName: "projectEdit" */ '@/components/carrier/UserGroupForm.vue'),
    props: true,
  },
  {
    path: '/user-group/edit/:id',
    name: 'userGroupEdit',
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.CARRIER,
    },
    component: () => import(/* webpackChunkName: "projectEdit" */ '@/components/carrier/UserGroupForm.vue'),
    props: true,
  },
  {
    path: '/address-book',
    name: 'address-book',
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.ADDRESS_BOOK,
    },
    component: () => import(/* webpackChunkName: "address-book" */ '@/views/AddressBook.vue'),
  },
  {
    path: '/address-book/edit',
    name: 'address-book-detail',
    component: () => import(/* webpackChunkName: "address-book-detail" */ '@/views/AddressBookDetail.vue'),
    props: true,
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.ADDRESS_BOOK,
    },
  },
  {
    path: '/product',
    name: 'product',
    component: () => import(/* webpackChunkName: "product" */ '@/views/Product.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.PRODUCT,
    },
  },
  {
    path: '/product/edit',
    name: 'product-detail',
    component: () => import(/* webpackChunkName: "product-detail" */ '@/views/ProductDetail.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.PRODUCT,
    },
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import(/* webpackChunkName: "staff" */ '@/views/Enterprise.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.ENTERPRISE,
    },
  },
  {
    path: '/enterprise/create',
    name: 'enterpriseCreate',
    component: () => import(/* webpackChunkName: "enterpriseCreate" */ '@/views/EnterpriseForm.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.ENTERPRISE,
    },
  },
  {
    path: '/enterprise/edit/:id',
    name: 'enterpriseEdit',
    component: () => import(/* webpackChunkName: "enterpriseEdit" */ '@/views/EnterpriseForm.vue'),
    props: true,
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.ENTERPRISE,
    },
  },
  {
    path: '/permission',
    name: 'permission',
    component: () => import(/* webpackChunkName: "permission" */ '@/views/Permission.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.PERMISSION,
    },
  },
  {
    path: '/barcode/search',
    name: 'barcodeSearch',
    component: () => import('@/views/Barcode.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.BARCODE_SEARCH,
    },
  },
  {
    path: '/barcode/:id',
    name: 'barcodeView',
    component: () => import('@/components/barcode/BarcodeDetail.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.BARCODE_SEARCH,
    },
  },
  {
    path: '/barcode-publish',
    name: 'barcodePublish',
    component: () => import('@/views/BarcodeImport.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.BARCODE_PUBLISH,
    },
  },
  {
    path: '/barcode-publish/:id',
    name: 'barcodeImportDetail',
    component: () => import('@/views/BarcodeImportDetail.vue'),
    meta: {
      restricted: true,
      menuKey: ESmartBarcodeMenu.BARCODE_PUBLISH,
    },
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/components/common/NotFound.vue'),
    meta: {
      header: {
        logo: false,
        homeButtton: true,
      },
      restricted: false,
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
