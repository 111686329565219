<template>
  <div class="file-processing" ref="fileProcessing" v-click-outside="hideFileProcessing">
    <div class="file-processing__container">
      <div class="file-processing__buttons" ref="fileProcessingButtons">
        <el-button type="primary" :class="isHaveNew ? 'isHaveNew' : ''" @click="onLoadData">{{
          $t('file_processing.file_output_status')
        }}</el-button>
      </div>
      <div class="file-processing__content">
        <a class="file-processing__btn-refresh btn-refresh" @click="onReloadData()"><IconRefresh /></a>
        <el-tabs @tab-click="changeTab" v-model="activeTabName">
          <el-tab-pane :name="fileProcessingTabs.barcodeData" :label="$t('file_processing.barcode_data')">
            <BarcodeExport ref="refBarcodeExport" />
          </el-tab-pane>
          <el-tab-pane v-if="isEnableArchive" :name="fileProcessingTabs.archive" :label="$t('archive_barcode')">
            <BarcodeArchive ref="refArchiveBarcode" />
          </el-tab-pane>
          <el-tab-pane
            v-if="isLoggedAsEnterprise"
            :name="fileProcessingTabs.accessLog"
            :label="$t('file_processing.access_log')"
          >
            <AccessLogExport ref="refAccessLogExport" />
          </el-tab-pane>
          <el-tab-pane
            v-if="isLoggedAsEnterprise"
            :name="fileProcessingTabs.location"
            :label="$t('file_processing.location_log')"
          >
            <LocationExport ref="refLocationExport" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import AccessLogExport from '@/components/fileProcessing/AccessLogExport.vue'
import BarcodeArchive from '@/components/fileProcessing/BarcodeArchive.vue'
import BarcodeExport from '@/components/fileProcessing/BarcodeExport.vue'
import LocationExport from '@/components/fileProcessing/LocationExport.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconCSV from '@/components/svg/IconCSV.vue'
import IconExcel from '@/components/svg/IconExcel.vue'
import IconPDF from '@/components/svg/IconPDF.vue'
import IconRefresh from '@/components/svg/IconRefresh.vue'
import { SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { ClickOutside } from '@/utils/directives'
import { EExportType, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    ItemNotFound,
    IconCSV,
    IconPDF,
    IconExcel,
    IconRefresh,
    BarcodeExport,
    AccessLogExport,
    LocationExport,
    BarcodeArchive,
  },
  emits: ['close'],
})
export default class FileProcessingPage extends mixins(DataTableMixin) {
  loading = false

  isShowClass = 'isShow'
  fileProcessingTabs = {
    barcodeData: 'barcodeData',
    archive: 'archive',
    accessLog: 'accessLog',
    location: 'location',
  }

  activeTabName = this.fileProcessingTabs.barcodeData
  oldActiveTabName = ''

  get isEnableArchive(): boolean {
    return this.$store.getters.isEnableArchive
  }

  get haveNew() {
    return this.$store.state.barcodeExport?.[EExportType.BARCODE].haveNew ?? 0
  }

  get haveNewAccessLog() {
    return this.$store.state.barcodeExport?.[EExportType.ACCESS_LOG].haveNew ?? 0
  }

  get haveNewLocation() {
    return this.$store.state.barcodeExport?.[EExportType.LOCATION].haveNew ?? 0
  }

  get haveNewArchive() {
    return this.$store.state.barcodeExport?.[EExportType.ARCHIVE].haveNew ?? 0
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsEnterprise() {
    return this.loggedUser?.userType === EUserType.ENTERPRISE || false
  }

  async onReloadData() {
    const obj = {
      [this.fileProcessingTabs.barcodeData]: this.$refs.refBarcodeExport,
      [this.fileProcessingTabs.accessLog]: this.$refs.refAccessLogExport,
      [this.fileProcessingTabs.location]: this.$refs.refLocationExport,
      [this.fileProcessingTabs.archive]: this.$refs.refArchiveBarcode,
    }
    obj[this.activeTabName].currentPageChange(1)
  }

  get isHaveNew() {
    return [this.haveNew, this.haveNewAccessLog, this.haveNewLocation].some((v) => v > 0)
  }

  @Watch('haveNew')
  @Watch('haveNewAccessLog')
  @Watch('haveNewLocation')
  applyAnimation() {
    const refFileProcressing = this.$refs.fileProcessingButtons
    if (this.isHaveNew) {
      refFileProcressing.style.right = '0rem'
      refFileProcressing.style.transition = 'right 0.05s'
      setTimeout(() => {
        refFileProcressing.style.right = '0.95rem'
        refFileProcressing.style.transition = 'right 0.1s'
        setTimeout(() => {
          refFileProcressing.style.right = '0.15rem'
          refFileProcressing.style.transition = 'right 0.08s'
          setTimeout(() => {
            refFileProcressing.style.right = '0.8rem'
            refFileProcressing.style.transition = 'right 0.07s'
            setTimeout(() => {
              refFileProcressing.style.right = '0.3rem'
              refFileProcressing.style.transition = 'right 0.06s'
              setTimeout(() => {
                refFileProcressing.style.right = '0.65rem'
                refFileProcressing.style.transition = 'right 0.05s'
                setTimeout(() => {
                  refFileProcressing.style.right = '0.4rem'
                  refFileProcressing.style.transition = 'right 0.035s'
                  setTimeout(() => {
                    refFileProcressing.style.right = '0.55rem'
                    refFileProcressing.style.transition = 'right 0.02s'
                    setTimeout(() => {
                      refFileProcressing.style.right = '0.5rem'
                      refFileProcressing.style.transition = 'right 0.01s'
                    }, 20)
                  }, 35)
                }, 50)
              }, 60)
            }, 70)
          }, 80)
        }, 100)
      }, 50)
    }
  }

  changeTab() {
    if (this.oldActiveTabName !== this.activeTabName) {
      this.onReloadData()
      this.oldActiveTabName = this.activeTabName
    }
  }

  hideFileProcessing() {
    const classList = this.$refs.fileProcessing.classList.value.split(' ')
    const indexIsShow = classList.indexOf(this.isShowClass) as number
    if (indexIsShow > -1) {
      classList.splice(indexIsShow, 1)
      this.$refs.fileProcessing.classList.value = [classList.join(' ')]
    }
  }

  async onLoadData() {
    const classList = this.$refs.fileProcessing.classList.value.split(' ')
    const indexIsShow = classList.indexOf(this.isShowClass) as number
    if (indexIsShow > -1) {
      classList.splice(indexIsShow, 1)
      this.$refs.fileProcessing.classList.value = [classList.join(' ')]
    } else {
      classList.push(this.isShowClass)
      this.$refs.fileProcessing.classList.value = [classList.join(' ')]
      if (this.haveNew === 0 && this.haveNewAccessLog > 0) {
        this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.ACCESS_LOG, isHaveNew: false })
        this.activeTabName = this.fileProcessingTabs.accessLog
      } else {
        this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: false })
      }
      this.onReloadData()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';
.file-processing {
  position: fixed;
  bottom: -0.5rem;
  right: 0.5rem;
  width: calc(100% - 240px - 2.5rem);
  z-index: 50;
  transform: translate(0, calc(100% + 2px - 0.5rem));
  transition: transform 0.5s;

  &.isShow {
    transform: translate(0, 0);

    .file-processing {
      &__content {
        left: 0;
      }
    }
  }

  &__container {
    position: relative;
  }

  &__buttons {
    position: absolute;
    right: 0.5rem;
    bottom: 100%;
    width: fit-content;
    button {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      &.isHaveNew {
        border-color: #2f80ed;
        background-color: #2f80ed;
      }
    }
  }

  &__content {
    background: #fafafa;
    border: 0.5px solid #a2a2a2;
    border-radius: 5px;
    padding: 0.75rem;
    height: 600px;
    max-height: calc(100vh - 40px - 1rem);
    position: relative;
  }

  &__btn-refresh {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 41px;
    width: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
  }
  .el-table {
    td {
      padding: 8px 0;
    }
  }
}
.file-processing:deep() {
  &.el-tabs {
    height: 100%;
    .el-tabs {
      &__content {
        max-height: calc(100% - 42px);
        overflow: auto;
        padding: 5px 0 !important;
      }
    }
  }
}
</style>
