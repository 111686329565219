
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class SmartBarcodeLogo extends Vue {
  @Prop({ type: Number, default: 240 }) readonly width?: number
  @Prop({ type: Number, default: 29 }) readonly height?: number
}
