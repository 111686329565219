
import { TABLE_SORT_ASC, TABLE_SORT_DESC } from '@/utils/constants'
import { displayDateTimeLocal } from '@/utils/timeUtils'
import cloneDeep from 'lodash/cloneDeep'
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'DataTableMixin',
})
export default class DataTableMixin<T> extends Vue {
  routeName = ''
  currentPageNum = 1
  totalPaging = 0
  itemsPerPage = 20
  displayItems = [] as T[]

  cleanString(data: string): string {
    return (data ?? '').trim().toLowerCase()
  }

  currentPageChange(pageNum: number, isRewriteUrl = true) {
    this.currentPageNum = pageNum
    if (isRewriteUrl) {
      this.redirectIndex()
    }
    this.filterItems()
  }

  redirectIndex() {
    const queryData = {} as Record<string, string>
    for (const key in this.dataSearch) {
      queryData[key] =
        key === 'sortOrder' ? (this.dataSearch[key] === 1 ? TABLE_SORT_ASC : TABLE_SORT_DESC) : this.dataSearch[key]
    }
    if (this.currentPageNum !== 1) queryData.page = this.currentPageNum.toString()
    this.$router.push({ name: this.routeName, query: queryData })
  }

  getQueryData() {
    const queryData = this.$route.query

    for (const key in queryData) {
      if (key !== 'page') {
        this.dataSearch[key] = key === 'sortOrder' ? (queryData[key] === TABLE_SORT_ASC ? 1 : -1) : queryData[key]
      }
    }

    if (queryData.page && queryData.page !== 1) {
      this.currentPageNum = Number(queryData.page)
    }
  }

  displayDateTime(date?: string) {
    return displayDateTimeLocal(this.$root.$i18n.locale, date)
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  getValueByPath(arrPaths: Array<string>, data: any): any {
    if (arrPaths.length === 1) {
      return data?.[arrPaths[0]]
    } else {
      const key = arrPaths.shift() as string
      return this.getValueByPath(arrPaths, data?.[key])
    }
  }

  sortDisplayItems(isPaging = true) {
    if (this.dataSearch.sortKey) {
      let sortKey = this.dataSearch.sortKey as keyof T
      const arrKeys = String(sortKey)
        .replaceAll('?', '')
        .split('.')
      const sortData = cloneDeep(this.displayItems)
      const newSortKey = 'newSortKey'
      if (arrKeys.length > 1) {
        sortData.map((item: any) => {
          item[newSortKey] = this.getValueByPath(cloneDeep(arrKeys), item)
          sortKey = newSortKey as keyof T
        })
      }

      sortData.sort((a: T, b: T) => {
        const firstValue = a?.[sortKey] ?? ''
        const secondValue = b?.[sortKey] ?? ''

        if (firstValue === '' || firstValue === null) return 1
        if (secondValue === '' || secondValue === null) return -1
        if (firstValue === secondValue) return 0
        return firstValue > secondValue ? this.dataSearch.sortOrder : 0 - this.dataSearch.sortOrder
      })

      if (arrKeys.length > 1) {
        sortData.map((item: any) => {
          delete item[newSortKey]
        })
      }
      this.displayItems = sortData
    }

    if (isPaging) {
      this.pagingItems()
    }
  }

  pagingItems() {
    const start = (this.currentPageNum - 1) * this.itemsPerPage
    const end = start + this.itemsPerPage
    this.totalPaging = this.displayItems?.length || 0
    this.displayItems = this.totalPaging > this.itemsPerPage ? this.displayItems?.slice(start, end) : this.displayItems

    if (this.isMergeMode) {
      this.selectOldTableData()
    }
  }

  updateTextSearch() {
    this.redirectIndex()
  }
}
