<template>
  <SvgBase iconName="File" width="14" height="14">
    <path
      d="M11 13.5H3C1.61929 13.5 0.5 12.3807 0.5 11V3C0.5 1.61929 1.61929 0.5 3 0.5H7.75736C8.4204 0.5 9.05628 0.763392 9.52513 1.23223L12.7678 4.47487C13.2366 4.94372 13.5 5.5796 13.5 6.24264V11C13.5 12.3807 12.3807 13.5 11 13.5Z"
      :stroke="color"
    />
    <line x1="3.5" y1="7.875" x2="10.5" y2="7.875" :stroke="color" />
    <line x1="3.5" y1="10.5" x2="10.5" y2="10.5" :stroke="color" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconImIconFileage extends Vue {}
</script>
