
import { IMPERSONATE } from '@/store/actions'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IImpersonatable } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Emit, PropSync } from 'vue-property-decorator'
export interface IOrganization {
  organizationId: string
  organizationName: string
}
export interface IUser {
  userId: string
  userName: string
  roleType: string
}
@Options({
  emits: ['update:isShow'],
  components: {},
  name: 'AccountSwitchingdialog',
})
export default class AccountSwitchingDialog extends Vue {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean

  impersonatables = [] as IImpersonatable[]
  impersonatableUsers = [] as IUser[]

  companySelected = ''

  itemsPerPage = 9
  curPageNum = 1

  get displayingUsers() {
    const from = (this.curPageNum - 1) * this.itemsPerPage
    const to = this.curPageNum * this.itemsPerPage
    return this.impersonatableUsers.filter((item, idx) => idx >= from && idx < to)
  }

  onRowClick(user: IUser) {
    this.$store.dispatch(IMPERSONATE, { id: user.userId }).then(() => (window.location.href = '/'))
    this.closeDialog()
  }

  onImpersonatableOrgChanged(orgId: string) {
    const selectedOrg = this.impersonatables.find((i) => i.organizationId === orgId)
    this.companySelected = selectedOrg?.organizationId ?? ''
    this.impersonatableUsers = (selectedOrg?.users ?? []) as IUser[]
  }

  created() {
    this.impersonatables = (this.$store.state.profile.impersonatables ?? []) as IImpersonatable[]
    if (!isEmpty(this.impersonatables)) this.onImpersonatableOrgChanged(this.impersonatables[0].organizationId)
  }

  @Emit('update:isShow')
  closeDialog() {
    this._isShow = false
  }
}
