
import LanguageSwitcher from '@/components/common/LanguageSwitcher.vue'
import IconArrowLeft from '@/components/svg/IconArrowLeft.vue'
import IconArrowRight from '@/components/svg/IconArrowRight.vue'
import IconDashboard from '@/components/svg/IconDashboard.vue'
import IconMenuAddressBook from '@/components/svg/IconMenuAddressBook.vue'
import IconMenuBarcode from '@/components/svg/IconMenuBarcode.vue'
import IconMenuBarcodeImport from '@/components/svg/IconMenuBarcodeImport.vue'
import IconMenuCarrier from '@/components/svg/IconMenuCarrier.vue'
import IconMenuClient from '@/components/svg/IconMenuClient.vue'
import IconMenuManual from '@/components/svg/IconMenuManual.vue'
import IconMenuProductList from '@/components/svg/IconMenuProductList.vue'
import IconMenuTimeTable from '@/components/svg/IconMenuTimeTable.vue'
import IconProject from '@/components/svg/IconProject.vue'
import IconStockroom from '@/components/svg/IconStockroom.vue'
import IconUser from '@/components/svg/IconUser.vue'
import IconVerticalThreeDots from '@/components/svg/IconVerticalThreeDots.vue'
import SmartBarcodeIcon from '@/components/svg/SmartBarcodeIcon.vue'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import { LOGOUT } from '@/store/actions'
import { ClickOutside } from '@/utils/directives'
import errorHandler from '@/utils/errorHandler'
import { isLocaleLanguage } from '@/utils/utils'
import { ELanguageLocale, ESmartBarcodeMenu, EUserRole, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IOrganization, IUser } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import AccountSwitchingDialog from './AccountSwitchingDialog.vue'

@Options({
  directives: {
    clickOutside: ClickOutside,
  },
  emits: ['collapse:update'],
  components: {
    IconMenuManual,
    LanguageSwitcher,
    AccountSwitchingDialog,
    IconUser,
    SmartBarcodeIcon,
    SmartBarcodeLogo,
    IconMenuClient,
    IconMenuAddressBook,
    IconMenuProductList,
    IconMenuCarrier,
    IconProject,
    IconMenuTimeTable,
    IconMenuBarcode,
    IconArrowLeft,
    IconArrowRight,
    IconVerticalThreeDots,
    IconMenuBarcodeImport,
    IconDashboard,
    IconStockroom,
  },
  name: 'AppSidebar',
})
export default class AppSidebar extends Vue {
  isCollapse = false
  isShowAction = false
  isSecondTime = false
  isShowAccountSwitchingDialog = false

  get loggedUser(): IUser {
    return this.$store.state.profile?.user
  }

  get isEnableTimetable(): boolean {
    return this.$store.getters.isEnableTimetable
  }

  get currentOrganization(): IOrganization {
    return this.$store.state.profile.organization
  }

  get currentImpersonatable() {
    const ou: IUser = this.$store.state.profile.originUser
    const u: IUser = this.$store.state.profile.user
    const o = this.currentOrganization
    return ou ? { userFullName: u.fullName, orgName: o.name ?? '' } : undefined
  }

  get isImpersonable() {
    return !!this.$store.state.profile.impersonatables
  }

  get isLoginPage() {
    return this.$route.name === 'login'
  }

  get isAuth() {
    return !!this.loggedUser
  }

  get isClient() {
    return this.loggedUser.userType === EUserType.CLIENT
  }

  get isOwner() {
    return this.loggedUser.roleType === EUserRole.OWNER
  }

  get entitledPortalUIViews() {
    return this.loggedUser.entitledPortalUIViews
  }

  get isSetDisplayedBarcodeByClient() {
    return this.currentOrganization.setDisplayedBarcodeByClient
  }

  get isEnterprise() {
    return this.loggedUser.userType === EUserType.ENTERPRISE
  }

  get isViewProject() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.PROJECT)
  }

  get isViewBarcodePublish() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.BARCODE_PUBLISH)
  }

  get isViewBarcodeSearch() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.BARCODE_SEARCH)
  }

  get isViewLocation() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.LOCATION)
  }

  get isViewDashboard() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.DASHBOARD)
  }

  get isViewCarrier() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.CARRIER)
  }

  get isViewClient() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.CLIENT)
  }

  get isViewProduct() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.PRODUCT)
  }

  get isViewAddressBook() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.ADDRESS_BOOK)
  }

  get isViewEnterprise() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.ENTERPRISE)
  }

  get isViewPermission() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.PERMISSION)
  }

  get isViewTimetable() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.TIMETABLE)
  }

  get isShowOnlineManual() {
    const arrOnlineManualSupport = [ELanguageLocale.EN, ELanguageLocale.JA]
    return arrOnlineManualSupport.some((lang) => isLocaleLanguage(lang))
  }

  classActiveMenu(menuKey: string) {
    return (this.$route?.meta?.menuKey === menuKey ? 'is-active' : '') + (this.isCollapse ? ' text-center' : '')
  }

  accountSwitching() {
    this.isShowAccountSwitchingDialog = true
  }

  confirmLogout() {
    this.$confirm(this.$t('confirm_logout'), this.$t('logout'), {
      confirmButtonText: this.$t('logout'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(() => this.logout())
      .catch(() => true)
  }

  setCollapse() {
    this.isCollapse = !this.isCollapse
    this.$emit('collapse:update', this.isCollapse)
    this.isShowAction = false
    this.isSecondTime = false
  }

  hideActions() {
    if (this.isCollapse && this.isShowAction) {
      if (this.isSecondTime) {
        this.isShowAction = false
        this.isSecondTime = false
      } else {
        this.isSecondTime = true
      }
    }
  }

  logout() {
    try {
      this.$store.dispatch(LOGOUT)
      this.gotoLogin()
    } catch (error) {
      errorHandler(error)
    }
  }

  gotoLogin() {
    this.$router.push({ name: 'login' })
  }

  goToManualSite() {
    const isEng = isLocaleLanguage(ELanguageLocale.EN)
    const url = `https://manual.smartbarcode.net${isEng ? '/en/' : '/'}`

    window.open(url, '_blank')
  }
}
