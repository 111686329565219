<template>
  <SvgBase iconName="IconTSV" :height="24" :width="21">
    <g filter="url(#filter0_d_10556_7925)">
      <path d="M17.5 19.5V7.5V6.5L12 1H0.5V19.5H17.5Z" fill="white" />
      <path
        d="M17.1527 4.60157C16.7066 3.99343 16.0843 3.28114 15.4016 2.59843C14.7189 1.91571 14.0066 1.29343 13.3984 0.847286C12.3621 0.0874285 11.8594 0 11.5714 0H1.60714C0.721286 0 0 0.721286 0 1.60714V18.9643C0 19.8501 0.721286 20.5714 1.60714 20.5714H16.3929C17.2787 20.5714 18 19.8501 18 18.9643V6.42857C18 6.14057 17.9126 5.63786 17.1527 4.60157V4.60157ZM14.4913 3.50871C15.1084 4.12586 15.5919 4.68129 15.9493 5.14286H12.8559V2.05071C13.3174 2.40814 13.8741 2.89157 14.49 3.50871H14.4913ZM16.7143 18.9643C16.7143 19.1391 16.5677 19.2857 16.3929 19.2857H1.60714C1.52221 19.2847 1.44103 19.2505 1.38097 19.1905C1.3209 19.1304 1.28672 19.0492 1.28571 18.9643V1.60714C1.28571 1.43357 1.43357 1.28571 1.60714 1.28571H11.5714V5.78571C11.5714 5.95621 11.6392 6.11972 11.7597 6.24028C11.8803 6.36084 12.0438 6.42857 12.2143 6.42857H16.7143V18.9643Z"
        fill="black"
      />
    </g>
    <path
      d="M8.13726 11.94C8.13726 12.17 8.19185 12.36 8.30102 12.5C8.4102 12.64 8.57397 12.72 8.79232 12.72C8.98338 12.72 9.13805 12.67 9.25632 12.56C9.3746 12.45 9.42918 12.29 9.42918 12.08C9.42918 11.91 9.38369 11.77 9.29271 11.68C9.20173 11.59 9.09256 11.52 8.97428 11.47L8.09177 11.12C7.74604 10.99 7.4822 10.8 7.30933 10.56C7.13647 10.32 7.04549 10 7.04549 9.62C7.04549 9.4 7.08188 9.19 7.14557 9C7.20926 8.81 7.31843 8.63 7.464 8.48C7.60957 8.33 7.80063 8.22 8.02808 8.13C8.25553 8.04 8.52848 8 8.84691 8C9.42009 8 9.8386 8.13 10.1115 8.4C10.3845 8.67 10.521 9.04 10.521 9.53V9.75H9.39279C9.39279 9.47 9.3564 9.27 9.26542 9.14C9.18354 9.01 9.03797 8.94 8.83781 8.94C8.68314 8.94 8.53757 8.99 8.4193 9.09C8.30102 9.19 8.23734 9.33 8.23734 9.53C8.23734 9.66 8.27373 9.78 8.34651 9.89C8.4193 10 8.56487 10.09 8.78322 10.16L9.53836 10.44C9.92958 10.59 10.2116 10.78 10.3754 11.01C10.5391 11.25 10.621 11.57 10.621 11.98C10.621 12.27 10.5755 12.51 10.4846 12.72C10.3936 12.93 10.2662 13.11 10.1024 13.25C9.93868 13.39 9.74762 13.49 9.52926 13.55C9.31091 13.61 9.06526 13.64 8.79232 13.64C8.43749 13.64 8.14636 13.6 7.9189 13.53C7.68236 13.46 7.50039 13.35 7.36392 13.2C7.22745 13.05 7.13647 12.88 7.08188 12.66C7.02729 12.45 7 12.22 7 11.96V11.77H8.12816V11.95L8.13726 11.94Z"
      fill="#231815"
    />
    <path
      d="M14.8333 8.14062L13.6051 13.5206H12.1494L10.8848 8.14062H12.1949L12.8954 11.9906H12.9136L13.5778 8.14062H14.8333Z"
      fill="#231815"
    />
    <path
      d="M4.10549 8.98936H3.00098L3 8H6.4418L6.44278 8.98936H5.36677L5.3658 13.58H4.10451L4.10549 8.98936Z"
      fill="black"
    />
    <defs>
      <filter
        id="filter0_d_10556_7925"
        x="0"
        y="0"
        width="20.5"
        height="23.0703"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.5" dy="1.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10556_7925" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10556_7925" result="shape" />
      </filter>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTSV extends Vue {}
</script>
