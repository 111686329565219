<template>
  <el-dialog
    center
    :model-value="visible"
    :show-close="!config.confirmation"
    :close-on-click-modal="false"
    :title="config.title"
    @closed="closeDialog"
    custom-class="el-dialog--no-heading"
  >
    <div v-html="message"></div>
    <template #footer v-if="config.confirmation">
      <span class="dialog-footer">
        <el-button type="primary" @click="closeDialog">
          {{ $t('ok') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import { CLOSE_DIALOG } from '@/store/actions'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  name: 'AppDialog',
})
export default class AppDialog extends Vue {
  get visible() {
    return this.$store.state.dialogVisible
  }

  get config() {
    return this.$store.state.dialogConfig
  }

  get message() {
    return this.config.message
  }

  closeDialog() {
    this.$store.dispatch(CLOSE_DIALOG)
  }
}
</script>
