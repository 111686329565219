<template>
  <div :class="[isCollapse ? '' : 'flex justify-center']">
    <div v-for="(lang, index) in languageSet.language" :key="lang" :class="[
      'rounded text-center text-tiny whitespace-nowrap font-bold w-11 py-1 cursor-pointer text-white mx-1',
      isSelected(lang) ? 'bg-gray-900 ' : 'bg-gray-300',
      isCollapse ? 'my-1 w-full' : '',
    ]" @click="setLanguage(lang)">
      {{ languageLabel(index) }}
    </div>
  </div>
</template>

<script lang="ts">
import { LANGUAGE_SET, LOCAL_STORAGE_ITEM } from '@/utils/constants'
import { ELanguageLocale } from 'smartbarcode-web-core/src/utils/enums/index'
import { isLocaleLanguage } from '@/utils/utils'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  name: 'LanguageSwitcher',
})
export default class LanguageSwitcher extends Vue {
  @Prop({ Boolean }) isCollapse!: boolean

  get languageSet() {
    return LANGUAGE_SET
  }

  languageLabel(index: number) {
    return this.languageSet.languageSwitcherLabel[index]
  }

  isSelected(lang: string) {
    if (this.isChinese) {
      return this.$root.$i18n.locale.split('-')[1] === lang.split('-')[1]
    }
    return this.$root.$i18n.locale.split('-')[0] === lang.split('-')[0]
  }

  get isChinese() {
    return isLocaleLanguage(ELanguageLocale.ZH)
  }

  get isJapanasese() {
    return isLocaleLanguage(ELanguageLocale.JA)
  }

  get isEnglish() {
    return isLocaleLanguage(ELanguageLocale.EN)
  }

  setLanguage(val: string) {
    this.$root.$i18n.locale = val
    localStorage.setItem(LOCAL_STORAGE_ITEM.LOCALE, val)
  }
}
</script>
