<template>
  <div class="nav-sidebar" v-if="!isLoginPage">
    <ul class="el-menu el-menu-sidebar" :class="{ ' el-menu--collapse': isCollapse }">
      <div
        v-if="isEnterprise"
        :class="['fixed z-50 top-0 bg-primary-pure-white', isCollapse ? 'w-24' : 'w-60', isImpersonable ? 'pt-4' : '']"
      >
        <div class="app-logo" index="0">
          <span class="app-logo__icon">
            <SmartBarcodeIcon />
          </span>
          <span class="app-logo__logo" v-if="!isCollapse">
            <SmartBarcodeLogo />
          </span>
        </div>
        <div v-show="isShowOnlineManual" @click="goToManualSite" class="flex justify-center">
          <div
            :class="[
              'manual-block items-center cursor-pointer text-center',
              isCollapse ? 'rounded-full w-10 h-10' : 'grid grid-cols-12 mx-10 py-1 rounded-xl',
            ]"
          >
            <IconMenuManual class="col-span-3 justify-self-center h-full" />
            <span v-if="!isCollapse" class="col-span-8 text-center">
              {{ $t('online_manual') }}
            </span>
          </div>
        </div>
        <div
          :class="
            isViewProject || isViewBarcodePublish || isViewBarcodeSearch
              ? 'border-solid border-b-1 border-gray-300'
              : ''
          "
        />
        <li v-if="isViewProject" class="el-menu-item" :class="classActiveMenu('projects')">
          <router-link :to="{ name: 'projects' }">
            <span class="el-icon--menu">
              <IconProject />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('Projects') }}</span>
          </router-link>
        </li>
        <li v-if="isViewBarcodePublish" class="el-menu-item" :class="classActiveMenu('barcodePublish')">
          <router-link :to="{ name: 'barcodePublish' }">
            <span class="el-icon--menu">
              <IconMenuBarcodeImport />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('barcodeImport.barcodeImport') }}</span>
          </router-link>
        </li>
        <li v-if="isViewBarcodeSearch" class="el-menu-item" :class="classActiveMenu('barcodeSearch')">
          <router-link :to="{ name: 'barcodeSearch' }">
            <span class="el-icon--menu">
              <IconMenuBarcode />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('barcode.search') }}</span>
          </router-link>
        </li>

        <div :class="isViewDashboard ? 'border-solid border-b-1 border-gray-300' : ''" />
        <li v-if="isViewDashboard" class="el-menu-item" :class="classActiveMenu('dashboard')">
          <router-link :to="{ name: 'dashboard' }">
            <span class="el-icon--menu">
              <IconDashboard />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('dashboard.dashboard') }}</span>
          </router-link>
        </li>
        <div v-if="isEnableTimetable && isViewTimetable" class="border-solid border-b-1 border-gray-300" />
        <li v-if="isEnableTimetable && isViewTimetable" class="el-menu-item" :class="classActiveMenu('timetable')">
          <router-link :to="{ name: 'timetable' }">
            <span class="el-icon--menu">
              <IconMenuTimeTable />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('time_tables') }}</span>
          </router-link>
        </li>

        <div :class="isViewClient || isViewCarrier ? 'border-solid border-b-1 border-gray-300' : ''" />
        <li v-if="isViewCarrier" class="el-menu-item" :class="classActiveMenu('carriers')">
          <router-link :to="{ name: 'carriers' }">
            <span class="el-icon--menu">
              <IconMenuCarrier />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('Staff') }}</span>
          </router-link>
        </li>

        <li v-if="isViewClient" class="el-menu-item" :class="classActiveMenu('clients')">
          <router-link :to="{ name: 'clients' }">
            <span class="el-icon--menu">
              <IconMenuClient />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('barcode_type.client') }}</span>
          </router-link>
        </li>
        <li v-if="isViewLocation" class="el-menu-item" :class="classActiveMenu('location')">
          <router-link :to="{ name: 'location' }">
            <span class="el-icon--menu">
              <IconStockroom style="height:26px;width:26px" />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('location.inventory') }}</span>
          </router-link>
        </li>
      </div>
      <div v-if="isClient" :class="['fixed z-50 top-0 bg-primary-pure-white', isCollapse ? 'w-24' : 'w-60']">
        <div class="app-logo" index="0">
          <span class="app-logo__icon">
            <SmartBarcodeIcon />
          </span>
          <span class="app-logo__logo" v-if="!isCollapse">
            <SmartBarcodeLogo />
          </span>
        </div>
        <li class="el-menu-item" :class="classActiveMenu('address-book')">
          <router-link :to="{ name: 'address-book' }">
            <span class="el-icon--menu">
              <IconMenuAddressBook />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('address_book.address_book') }}</span>
          </router-link>
        </li>
        <li class="el-menu-item" :class="classActiveMenu('product')">
          <router-link :to="{ name: 'product' }">
            <span class="el-icon--menu">
              <IconMenuProductList />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('product.product_list') }}</span>
          </router-link>
        </li>
        <li v-if="isSetDisplayedBarcodeByClient" class="el-menu-item" :class="classActiveMenu('barcodeSearch')">
          <router-link :to="{ name: 'barcodeSearch' }">
            <span class="el-icon--menu">
              <IconMenuBarcode />
            </span>

            <span v-if="!isCollapse" class="el-menu--parent">{{ $t('barcode.search') }}</span>
          </router-link>
        </li>
      </div>
    </ul>

    <div v-click-outside="hideActions" :class="['nav-bottom', isCollapse ? 'is-collapse nav-bottom-relative' : '']">
      <div :class="['account-box', currentImpersonatable ? 'account-box-border' : '']">
        <div class="account-box__avatar self-center">
          <el-avatar icon="el-icon-user-solid"></el-avatar>
          <div class="account-box__avatar__more-button">
            <el-button
              plain
              class="account-box__more-button"
              v-if="isCollapse && !isShowAction"
              @click="isShowAction = !isShowAction"
            >
              <IconVerticalThreeDots />
            </el-button>
          </div>
        </div>
        <div
          :class="[
            'account-box__user-info',
            isCollapse ? (isShowAction ? 'nav-bottom-relative__absolute' : 'nav-bottom-relative__hidden') : '',
          ]"
        >
          <div class="mb-2 truncate leading-normal w-11/12">{{ loggedUser?.fullName }}</div>
          <div v-if="isOwner">
            <router-link :to="{ name: 'enterprise' }">
              <el-button class="w-full el-button--low-height mt-1 px-3 mb-2">
                {{ $t('admin_menu') }}
              </el-button>
            </router-link>
          </div>
          <div v-if="isImpersonable">
            <el-button @click="accountSwitching" class="w-full el-button--low-height mt-1 px-3">
              {{ $t('account_switching') }}
            </el-button>
          </div>
          <div>
            <!-- Logout -->
            <el-button @click="confirmLogout" class="w-full el-button--low-height mt-1">{{ $t('logout') }}</el-button>
          </div>
        </div>
      </div>
      <div class="nav-bottom__collapse-button">
        <LanguageSwitcher :class="['', isCollapse ? '' : '']" :isCollapse="isCollapse" />

        <el-button circle @click="setCollapse()">
          <IconArrowLeft v-if="!isCollapse" />
          <IconArrowRight v-else />
        </el-button>
      </div>
    </div>
  </div>

  <AccountSwitchingDialog v-model:isShow="isShowAccountSwitchingDialog" />
</template>

<script lang="ts">
import LanguageSwitcher from '@/components/common/LanguageSwitcher.vue'
import IconArrowLeft from '@/components/svg/IconArrowLeft.vue'
import IconArrowRight from '@/components/svg/IconArrowRight.vue'
import IconDashboard from '@/components/svg/IconDashboard.vue'
import IconMenuAddressBook from '@/components/svg/IconMenuAddressBook.vue'
import IconMenuBarcode from '@/components/svg/IconMenuBarcode.vue'
import IconMenuBarcodeImport from '@/components/svg/IconMenuBarcodeImport.vue'
import IconMenuCarrier from '@/components/svg/IconMenuCarrier.vue'
import IconMenuClient from '@/components/svg/IconMenuClient.vue'
import IconMenuManual from '@/components/svg/IconMenuManual.vue'
import IconMenuProductList from '@/components/svg/IconMenuProductList.vue'
import IconMenuTimeTable from '@/components/svg/IconMenuTimeTable.vue'
import IconProject from '@/components/svg/IconProject.vue'
import IconStockroom from '@/components/svg/IconStockroom.vue'
import IconUser from '@/components/svg/IconUser.vue'
import IconVerticalThreeDots from '@/components/svg/IconVerticalThreeDots.vue'
import SmartBarcodeIcon from '@/components/svg/SmartBarcodeIcon.vue'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import { LOGOUT } from '@/store/actions'
import { ClickOutside } from '@/utils/directives'
import errorHandler from '@/utils/errorHandler'
import { isLocaleLanguage } from '@/utils/utils'
import { ELanguageLocale, ESmartBarcodeMenu, EUserRole, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IOrganization, IUser } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import AccountSwitchingDialog from './AccountSwitchingDialog.vue'

@Options({
  directives: {
    clickOutside: ClickOutside,
  },
  emits: ['collapse:update'],
  components: {
    IconMenuManual,
    LanguageSwitcher,
    AccountSwitchingDialog,
    IconUser,
    SmartBarcodeIcon,
    SmartBarcodeLogo,
    IconMenuClient,
    IconMenuAddressBook,
    IconMenuProductList,
    IconMenuCarrier,
    IconProject,
    IconMenuTimeTable,
    IconMenuBarcode,
    IconArrowLeft,
    IconArrowRight,
    IconVerticalThreeDots,
    IconMenuBarcodeImport,
    IconDashboard,
    IconStockroom,
  },
  name: 'AppSidebar',
})
export default class AppSidebar extends Vue {
  isCollapse = false
  isShowAction = false
  isSecondTime = false
  isShowAccountSwitchingDialog = false

  get loggedUser(): IUser {
    return this.$store.state.profile?.user
  }

  get isEnableTimetable(): boolean {
    return this.$store.getters.isEnableTimetable
  }

  get currentOrganization(): IOrganization {
    return this.$store.state.profile.organization
  }

  get currentImpersonatable() {
    const ou: IUser = this.$store.state.profile.originUser
    const u: IUser = this.$store.state.profile.user
    const o = this.currentOrganization
    return ou ? { userFullName: u.fullName, orgName: o.name ?? '' } : undefined
  }

  get isImpersonable() {
    return !!this.$store.state.profile.impersonatables
  }

  get isLoginPage() {
    return this.$route.name === 'login'
  }

  get isAuth() {
    return !!this.loggedUser
  }

  get isClient() {
    return this.loggedUser.userType === EUserType.CLIENT
  }

  get isOwner() {
    return this.loggedUser.roleType === EUserRole.OWNER
  }

  get entitledPortalUIViews() {
    return this.loggedUser.entitledPortalUIViews
  }

  get isSetDisplayedBarcodeByClient() {
    return this.currentOrganization.setDisplayedBarcodeByClient
  }

  get isEnterprise() {
    return this.loggedUser.userType === EUserType.ENTERPRISE
  }

  get isViewProject() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.PROJECT)
  }

  get isViewBarcodePublish() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.BARCODE_PUBLISH)
  }

  get isViewBarcodeSearch() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.BARCODE_SEARCH)
  }

  get isViewLocation() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.LOCATION)
  }

  get isViewDashboard() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.DASHBOARD)
  }

  get isViewCarrier() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.CARRIER)
  }

  get isViewClient() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.CLIENT)
  }

  get isViewProduct() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.PRODUCT)
  }

  get isViewAddressBook() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.ADDRESS_BOOK)
  }

  get isViewEnterprise() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.ENTERPRISE)
  }

  get isViewPermission() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.PERMISSION)
  }

  get isViewTimetable() {
    return this.entitledPortalUIViews.includes(ESmartBarcodeMenu.TIMETABLE)
  }

  get isShowOnlineManual() {
    const arrOnlineManualSupport = [ELanguageLocale.EN, ELanguageLocale.JA]
    return arrOnlineManualSupport.some((lang) => isLocaleLanguage(lang))
  }

  classActiveMenu(menuKey: string) {
    return (this.$route?.meta?.menuKey === menuKey ? 'is-active' : '') + (this.isCollapse ? ' text-center' : '')
  }

  accountSwitching() {
    this.isShowAccountSwitchingDialog = true
  }

  confirmLogout() {
    this.$confirm(this.$t('confirm_logout'), this.$t('logout'), {
      confirmButtonText: this.$t('logout'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(() => this.logout())
      .catch(() => true)
  }

  setCollapse() {
    this.isCollapse = !this.isCollapse
    this.$emit('collapse:update', this.isCollapse)
    this.isShowAction = false
    this.isSecondTime = false
  }

  hideActions() {
    if (this.isCollapse && this.isShowAction) {
      if (this.isSecondTime) {
        this.isShowAction = false
        this.isSecondTime = false
      } else {
        this.isSecondTime = true
      }
    }
  }

  logout() {
    try {
      this.$store.dispatch(LOGOUT)
      this.gotoLogin()
    } catch (error) {
      errorHandler(error)
    }
  }

  gotoLogin() {
    this.$router.push({ name: 'login' })
  }

  goToManualSite() {
    const isEng = isLocaleLanguage(ELanguageLocale.EN)
    const url = `https://manual.smartbarcode.net${isEng ? '/en/' : '/'}`

    window.open(url, '_blank')
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

$width-menu-open: 240px;
$width-menu-collapse: 96px;

.manual-block:hover {
  background: #5a6af1;

  span {
    color: white;
  }
}

.manual-block {
  font-weight: bold;
  background: #d9d9d9;
  margin-bottom: 23px;

  span {
    font-size: 10px;
    align-self: center;
  }
}

.el-menu-sidebar {
  font-family: 'Noto Sans', sans-serif;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: none;
  flex-grow: 1 !important;
  width: $width-menu-open;
  min-height: 100vh;

  &.el-menu--collapse {
    width: $width-menu-collapse;
  }

  & a {
    text-decoration: none;
  }

  .app-logo {
    padding: 10px 0 10px 0;
    text-align: center;
    white-space: nowrap;

    &__logo svg {
      width: 124px;
      margin-left: 10px;
    }
  }

  .user-menu {
    margin-left: auto;
    border: none;
  }

  .el-menu {
    &--parent {
      font-weight: 600;
      margin-left: 20px;
    }

    &--inline {
      .el-menu-item {
        padding-left: 84px !important;
      }
    }
  }

  .el-icon--menu {
    width: 40px !important;
    height: initial !important;
    text-align: center;
    display: inline-block;
    visibility: inherit !important;
  }

  .el-menu-item {
    a {
      color: $pure-black;
      font-size: 16px;
      display: block;
    }

    svg {
      stroke: #c4c4c4;
      fill: #c4c4c4;
    }

    &.is-active {
      color: $main;
      background-color: $body-bg;

      svg {
        stroke: #5a6af1;
        fill: #5a6af1;
      }
    }
  }
}

.nav-bottom {
  position: fixed;
  z-index: 49;
  bottom: 0;
  width: $width-menu-open;
  background-color: #fff;

  &__collapse-button {
    text-align: right;
    margin: 10px 10px 0 10px;

    button {
      border: none;
      padding: 5px;
      width: 40px;
      height: 40px;
    }
  }

  &.is-collapse {
    width: $width-menu-collapse;

    .account-box {
      display: block;
      text-align: center;
      background: none;
      padding: 15px 0px 0px 0px;
    }

    .nav-bottom__collapse-button {
      text-align: center;
    }
  }

  .account-box {
    display: flex;
    margin: 0px 20px;
    border-radius: 5px;
    background-color: #f5f6fa;
    padding: 13px 12px 10px 16px;

    &__avatar {
      position: relative;
      height: 92px;
    }

    &__user-info {
      flex-grow: 1;
      margin-left: 10px;
      padding: 13px 0px 0px;
      width: 110px;

      .el-button {
        font-size: 12px;
      }
    }

    &__more-button {
      border: none;
      padding: 2px;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
    }

    &__avatar {
      &__more-button {
        text-align: center;
        margin-top: 11px;
      }
    }
  }

  .el-dropdown-menu {
    &__item {
      a {
        color: $pure-black;
      }
    }
  }

  .nav-bottom-relative {
    position: relative;

    &__hidden {
      display: none;
    }

    &__absolute {
      background-color: #fff;
      position: absolute;
      top: -25px;
      left: calc(100% - 10px);
      width: 128px;
      padding: 33px 15px 15px;
      text-align: left;
      margin-left: 0px;
      border-radius: 0px 5px 5px 0px;
    }
  }

  .el-button {
    border-color: #d4d4d4;
  }

  .account-box-border {
    border: 3px solid #de8181;
    border-radius: 5px;
  }
}
</style>
