<template>
  <SvgBase iconName="IconMenuBarcodeImport" :height="24" :width="24">
    <path
      d="M11 12.5H9.5V11.5H12.5V14.5H11.5V13V12.5H11ZM16.5 4H16V4.5V7.5V8H16.5H19.5H20V7.5V4.5V4H19.5H16.5ZM4.5 4H4V4.5V7.5V8H4.5H7.5H8V7.5V4.5V4H7.5H4.5ZM4.5 16H4V16.5V19.5V20H4.5H7.5H8V19.5V16.5V16H7.5H4.5ZM3.5 11.5H4.5V12.5H3.5V11.5ZM11.5 5.5H12.5V8.5H11.5V5.5ZM15.5 3.5H20.5V8.5H15.5V3.5ZM3.5 3.5H8.5V8.5H3.5V3.5ZM3.5 15.5H8.5V20.5H3.5V15.5Z"
    />
    <circle cx="16" cy="16" r="6" />
    <path
      d="M17.0549 13.0545L19.8012 15.8008L17.0549 18.5471"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <line
      x1="12.75"
      y1="15.75"
      x2="18.25"
      y2="15.75"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMenuBarcodeImport extends Vue {}
</script>
