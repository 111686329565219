<template>
  <SvgBase iconName="PhoneNumber" width="14" height="14">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" :stroke="color" />
      <path
        d="M10.9827 9.19839C11.046 9.03504 10.9227 8.71166 10.826 8.66499C10.1462 8.35495 9.62634 8.19827 8.82988 8.02158C8.72991 7.99824 8.44332 8.04825 8.38666 8.13493C8.24337 8.35829 8.1034 8.58165 7.96344 8.80167C7.83681 9.00837 7.48356 9.02503 7.19364 8.81501C6.81041 8.53498 6.4505 8.22494 6.11392 7.89157C5.78067 7.55152 5.47075 7.19148 5.18749 6.8081C4.97755 6.51807 4.99088 6.16802 5.20082 6.03801C5.4241 5.89799 5.64737 5.75464 5.86732 5.61463C5.95396 5.55795 6.00728 5.27125 5.98062 5.17124C5.81067 4.52449 5.57406 3.83108 5.33746 3.17433C5.3008 3.07432 4.96755 2.95431 4.80426 3.01765C4.26773 3.22767 3.76786 3.54438 3.33797 3.97443C3.218 4.09444 3.10803 4.22446 3.00139 4.35447C2.96474 5.89466 3.65123 7.6082 5.00754 8.96503C5.01087 8.96836 5.01421 8.9717 5.02087 8.97503C5.0242 8.97836 5.03087 8.98503 5.0342 8.9917C6.39052 10.3485 8.1034 11.0353 9.643 10.9986C9.7763 10.8953 9.90294 10.7852 10.0229 10.6619C10.4495 10.2318 10.7694 9.73179 10.976 9.19506L10.9827 9.19839Z"
        :fill="color"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'
@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPhoneNumber extends Vue {}
</script>
