import moment from 'moment-timezone'
import { Moment } from 'moment'
import { TIMETABLE_DATETIME } from '@/utils/constants'

export function momentOf(date: string): Moment {
  return moment(date, TIMETABLE_DATETIME.DATE_PARAM_FORMAT)
}

export function displayMomentAtParam(moment: Moment): string {
  return moment.format(TIMETABLE_DATETIME.DATE_PARAM_FORMAT)
}

export function displayMomentAtDate(moment: Moment): string {
  return moment.format(TIMETABLE_DATETIME.DATE_DISPLAY_FORMAT)
}

export function displayMomentAtLLDate(moment: Moment): string {
  return moment.format(TIMETABLE_DATETIME.LL_DATE_DISPLAY_FORMAT)
}

export function displayMomentAtTime(moment: Moment): string {
  return moment.format(TIMETABLE_DATETIME.TIME_WITHOUT_SECOND_FORMAT)
}

export function displayMomentAtDateTime(moment: Moment) {
  return moment.format(TIMETABLE_DATETIME.DATE_TIME_FORMAT)
}

export function displayParamAtTime(timeParam: string | undefined): string {
  return displayMomentAtTime(moment(timeParam))
}

export function isRelativeTimeFormat(time: string): boolean {
  const relativeTimeRegex = /^([0-1][0-9]|2[0-3]):[0-5][0-9][+|-][\d]+$/
  return relativeTimeRegex.test(time)
}

export function displayRelativeTime(scheduleTime: string | undefined, selectedMoment: Moment) {
  const scheduleMoment = moment(scheduleTime)
  const selectedDateWithTime = moment(
    `${displayMomentAtDate(selectedMoment)} ${displayMomentAtTime(scheduleMoment)}`,
    TIMETABLE_DATETIME.DATETIME_WITHOUT_SECOND_FORMAT
  )
  const diffDaysNum = scheduleMoment.diff(selectedDateWithTime, 'days')

  return `${displayParamAtTime(scheduleTime)}${diffDaysNum >= 0 ? '+' : ''}${diffDaysNum}`
}

export function getRelativeTime(scheduleTime: string | undefined, selectedDate: string) {
  const scheduleMoment = moment(scheduleTime)
  const selectedDateFormat = displayMomentAtDate(moment(selectedDate, TIMETABLE_DATETIME.DATE_PARAM_FORMAT))
  const scheduleMomentTime = displayMomentAtTime(scheduleMoment)

  const selectedDateWithTime = moment(
    `${selectedDateFormat} ${scheduleMomentTime}`,
    TIMETABLE_DATETIME.DATETIME_WITHOUT_SECOND_FORMAT
  )

  const diffDaysNum = scheduleMoment.diff(selectedDateWithTime, 'days')

  return `${scheduleMomentTime}${diffDaysNum >= 0 ? '+' : ''}${diffDaysNum}`
}

export function displayDateTimeLocal(locale: string, date?: string) {
  if (date) {
    return displayMomentAtDateTime(
      moment
        .utc(date)
        .local()
        .locale(locale)
    )
  } else {
    return ''
  }
}

export function getCurrentUtcOffset() {
  return moment().utcOffset() / 60
}

export function convertStringToDate(value: string) {
  return moment(value, TIMETABLE_DATETIME.DATE_PARAM_FORMAT).toDate()
}
