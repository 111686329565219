/* eslint-disable no-useless-catch */
import {
  IAccessLogResponse,
  IBarcodeExportResponse,
  IExportPaginationParams,
  ILocationResponse,
} from 'smartbarcode-web-core/src/utils/types/index'

import { getAccessLogOutput, getBarcodeArchiveProcessList, getBarcodeExport, getLocationExportList } from '@/utils/api'

import { EExportType } from 'smartbarcode-web-core/src/utils/enums/index'

import {
  LOAD_ACESS_LOG_EXPORT_LIST,
  LOAD_BARCODE_ARCHIVE_LIST,
  LOAD_BARCODE_EXPORT_LIST,
  LOAD_EXPORT_LIST,
  LOAD_LOCATION_EXPORT_LIST,
  SET_HAVE_NEW_EXPORT_RECORD,
} from './actions'

export interface IExportState {
  haveNew: number
  total: number
  data: (IBarcodeExportResponse | IAccessLogResponse | ILocationResponse)[]
}

const DEFAULT_PROCESSING_RECORDS = { haveNew: 0, data: [], total: 0 }

type TExportStates = {
  [EExportType.BARCODE]: IExportState
  [EExportType.ACCESS_LOG]: IExportState
  [EExportType.LOCATION]: IExportState
  [EExportType.ARCHIVE]: IExportState
}

export default {
  state: {
    [EExportType.BARCODE]: { ...DEFAULT_PROCESSING_RECORDS },
    [EExportType.ACCESS_LOG]: { ...DEFAULT_PROCESSING_RECORDS },
    [EExportType.LOCATION]: { ...DEFAULT_PROCESSING_RECORDS },
    [EExportType.ARCHIVE]: { ...DEFAULT_PROCESSING_RECORDS },
  },
  mutations: {
    [LOAD_EXPORT_LIST]: (
      state: TExportStates,
      {
        exportType,
        data,
        total,
      }: {
        exportType: EExportType
        data: (IBarcodeExportResponse | IAccessLogResponse | ILocationResponse)[]
        total: number
      }
    ) => {
      state[exportType] = { haveNew: 0, data, total }
    },
    [SET_HAVE_NEW_EXPORT_RECORD]: (
      state: TExportStates,
      {
        exportType,
        isHaveNew,
      }: {
        exportType: EExportType
        isHaveNew: boolean
      }
    ) => {
      state[exportType].haveNew = isHaveNew ? state[exportType].haveNew + 1 : 0
    },
  },
  actions: {
    [LOAD_BARCODE_EXPORT_LIST]: async ({ commit }: { commit: Function }, data: IExportPaginationParams) => {
      let exportData = { exportType: EExportType.BARCODE, data: [], total: 0 }
      try {
        const { barcodeExports, count } = await getBarcodeExport(data)
        exportData = { ...exportData, data: barcodeExports, total: count }
      } catch (err) {}
      commit(LOAD_EXPORT_LIST, exportData)
    },
    [LOAD_ACESS_LOG_EXPORT_LIST]: async ({ commit }: { commit: Function }, data: IExportPaginationParams) => {
      let exportData = { exportType: EExportType.ACCESS_LOG, data: [], total: 0 }
      try {
        const { logExports, count } = await getAccessLogOutput(data)
        exportData = { ...exportData, data: logExports, total: count }
      } catch (err) {}
      commit(LOAD_EXPORT_LIST, exportData)
    },
    [LOAD_LOCATION_EXPORT_LIST]: async ({ commit }: { commit: Function }, data: IExportPaginationParams) => {
      let exportData = { exportType: EExportType.LOCATION, data: [], total: 0 }
      try {
        const { barcodeExports, count } = await getLocationExportList(data)
        exportData = { ...exportData, data: barcodeExports, total: count }
      } catch (err) {}
      commit(LOAD_EXPORT_LIST, exportData)
    },
    [LOAD_BARCODE_ARCHIVE_LIST]: async ({ commit }: { commit: Function }, data: IExportPaginationParams) => {
      let exportData = { exportType: EExportType.ARCHIVE, data: [], total: 0 }
      try {
        const { barcodeArchives, count } = await getBarcodeArchiveProcessList(data)
        exportData = { ...exportData, data: barcodeArchives, total: count }
      } catch (err) {}
      commit(LOAD_EXPORT_LIST, exportData)
    },
  },
}
