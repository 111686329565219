<template>
  <SvgBase iconName="Project" :width="27" :height="24">
    <rect
      x="4.95508"
      y="4"
      width="6.75716"
      height="6.22222"
      stroke-width="1.5"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="15.5732"
      y="4"
      width="6.75716"
      height="6.22222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <rect
      x="15.5732"
      y="13.7773"
      width="6.75716"
      height="6.22222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <rect
      x="4.95508"
      y="13.7773"
      width="6.75716"
      height="6.22222"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconProject extends Vue {}
</script>
