<template>
  <SvgBase iconName="IconReference" width="14" height="14">
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5713 9H12.2062C13.748 9 15 7.93976 15 6.63417C15 5.32859 13.748 4.26835 12.2062 4.26835C12.1855 4.26835 12.1648 4.26835 12.1545 4.26835C12.1545 2.46331 10.4265 1 8.28455 1C6.54619 1 5.08721 1.98138 4.60089 3.32202C4.51811 3.32202 4.44568 3.30449 4.3629 3.30449C2.50037 3.30449 1 4.58379 1 6.15225C1 7.7207 2.51072 9 4.3629 9H5.72875"
        :stroke="color"
        stroke-width="1.05"
        stroke-miterlimit="10"
      />
      <path d="M7.77002 13.5781V7.32812" :stroke="color" stroke-width="1.05" stroke-miterlimit="10" />
      <path d="M5.25 11.3984L7.77 13.9184L10.29 11.3984" :stroke="color" stroke-width="1.05" stroke-miterlimit="10" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconReference extends Vue {}
</script>
