<template>
  <SvgBase iconName="GroupOfCode" width="32" height="32">
    <circle cx="16" cy="16" r="16" fill="white" />
    <path
      d="M23.2898 11.2035C21.9644 9.21534 19.3135 7.22717 15.9999 7.22717C10.6981 7.22717 7.38452 11.8662 7.38452 15.8426C7.38452 19.8189 10.6981 24.4579 15.9999 24.4579C19.4619 24.4579 22.0761 22.4799 23.4736 20M23.2898 11.2035L18.6508 11.8662M23.2898 11.2035L23.9526 6.56445"
      stroke="#2E7CF6"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'
@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconRefresh extends Vue {}
</script>
