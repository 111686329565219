
import { CLOSE_DIALOG } from '@/store/actions'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {},
  name: 'AppDialog',
})
export default class AppDialog extends Vue {
  get visible() {
    return this.$store.state.dialogVisible
  }

  get config() {
    return this.$store.state.dialogConfig
  }

  get message() {
    return this.config.message
  }

  closeDialog() {
    this.$store.dispatch(CLOSE_DIALOG)
  }
}
