<template>
  <SvgBase iconName="SmartBarcodeIcon" :width="24" :height="29">
    <g fill="none">
      <path
        d="M6.68022 25.4681C9.15786 25.4681 11.4473 23.9609 11.4473 21.4694C11.4473 17.9322 4.20258 17.8092 1.88175 14.1182L0 24.5145C0.12545 24.5145 0.219538 24.5453 0.344988 24.5761C1.31723 24.8836 4.20258 25.4681 6.68022 25.4681Z"
        fill="#5A6AF1"
      />
      <path
        d="M18.9746 13.3184C21.8913 11.9958 23.7103 9.53514 23.7103 6.21322C23.7103 2.5222 20.825 0 15.7756 0H5.8964C5.01825 0 4.29691 0.584412 4.10873 1.50717L3.19922 6.64384C5.20642 4.55226 8.43676 3.32192 11.7612 3.32192C14.1134 3.32192 15.9638 3.81406 17.4065 4.27543C18.1905 4.5215 18.5355 5.22895 18.2532 5.90563L17.6887 7.19749C17.4065 7.87418 16.591 8.15101 15.7756 7.90494C14.5525 7.53584 13.1411 7.16673 11.2908 7.16673C8.49949 7.16673 6.86864 8.61238 6.86864 10.5809C6.86864 14.5488 16.4969 14.2104 16.4969 20.7312C16.4969 24.2992 14.3956 26.6676 11.6044 28.021C18.755 27.6827 22.5813 23.1919 22.5813 18.3628C22.5813 16.0867 21.3895 14.272 18.9746 13.3184Z"
        fill="#535353"
      />
    </g>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class SmartBarcodeIcon extends Vue {}
</script>
