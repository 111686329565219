<template>
  <SvgBase iconName="MenuAddressBook" :width="16" :height="18">
    <path
      d="M10.5769 5.625C10.5769 6.55016 9.74191 7.375 8.61535 7.375C7.48878 7.375 6.65381 6.55016 6.65381 5.625C6.65381 4.69984 7.48878 3.875 8.61535 3.875C9.74191 3.875 10.5769 4.69984 10.5769 5.625Z"
      fill="none"
    />
    <path
      d="M11.0769 10.125C11.0769 8.88236 9.97482 7.875 8.61535 7.875C7.25588 7.875 6.15381 8.88236 6.15381 10.125"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <rect x="1.73071" y="0.5" width="13.7692" height="17" rx="1.5" fill="none" />
    <line x1="0.5" y1="6.25" x2="1.96154" y2="6.25" stroke-linecap="round" fill="none" />
    <line x1="5.4231" y1="11.875" x2="11.8077" y2="11.875" stroke-linecap="round" fill="none" />
    <line x1="5.4231" y1="14.125" x2="11.8077" y2="14.125" stroke-linecap="round" fill="none" />
    <line x1="0.5" y1="13" x2="1.96154" y2="13" stroke-linecap="round" fill="none" />
    <line x1="0.5" y1="10.75" x2="1.96154" y2="10.75" stroke-linecap="round" fill="none" />
    <line x1="0.5" y1="8.5" x2="1.96154" y2="8.5" stroke-linecap="round" fill="none" />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'
@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMenuAddressBook extends Vue {}
</script>
