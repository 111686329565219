/* eslint-disable no-useless-catch */
import store from '@/store'
import { getEnterprise, getEnterpriseList } from '@/utils/api'
import { EEncodingType, ESmartBarcodeMenu } from 'smartbarcode-web-core/src/utils/enums/index'
import { IEnterprise, IEnterpriseList } from 'smartbarcode-web-core/src/utils/types/index'
import { CLEAR_ALL, CLEAR_DETAIL_ENTERPRISE, FETCH_ENTERPRISE, LOAD_ENTERPRISE_LIST, SAVE_ENTERPRISE } from './actions'

type TEnterpriseStates = {
  isLoaded: boolean
  enterprises: Array<IEnterpriseList>
  enterprise: IEnterprise
}

function getInitEnterpriseDetailState(): IEnterprise {
  const basicEnterpriseDetailState = {
    isEdit: false,
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    roleType: '',
    isActive: true,
    encodingType: EEncodingType.UTF_8,
    entitledPortalUIViews: [] as string[],
    visibleProjectCodes: [] as string[],
  }

  if (store?.getters?.isEnableTimetable) {
    basicEnterpriseDetailState.entitledPortalUIViews = {
      ...basicEnterpriseDetailState.entitledPortalUIViews,
      ...[ESmartBarcodeMenu.TIMETABLE],
    }
  }

  return basicEnterpriseDetailState
}

export default {
  state: {
    isLoaded: false,
    enterprises: [],
    enterprise: getInitEnterpriseDetailState(),
  },
  mutations: {
    [LOAD_ENTERPRISE_LIST]: (
      state: TEnterpriseStates,
      {
        enterprises,
      }: {
        enterprises: Array<IEnterpriseList>
      }
    ) => {
      state.isLoaded = true
      state.enterprises = enterprises
    },
    [SAVE_ENTERPRISE]: (state: TEnterpriseStates, enterprise: IEnterprise) => {
      state.enterprise = { ...enterprise }
    },
    [CLEAR_DETAIL_ENTERPRISE]: (state: TEnterpriseStates) => {
      state.enterprise = getInitEnterpriseDetailState()
    },
    [CLEAR_ALL]: (state: TEnterpriseStates) => {
      state.isLoaded = false
      state.enterprises = []
      state.enterprise = getInitEnterpriseDetailState()
    },
  },
  actions: {
    [LOAD_ENTERPRISE_LIST]: async ({ commit }: { commit: Function }) => {
      try {
        const enterprises: Array<IEnterpriseList> = await getEnterpriseList()
        commit(LOAD_ENTERPRISE_LIST, { enterprises })
      } catch (err) {
        throw err
      }
    },

    [FETCH_ENTERPRISE]: async (
      {
        commit,
      }: {
        commit: Function
        dispatch: Function
        state: TEnterpriseStates
      },
      userId: string
    ) => {
      try {
        const response = (await getEnterprise(userId)) as Record<string, unknown>

        const enterprise = ({
          ...response,
          isEdit: true,
          encodingType: response?.encodingType === EEncodingType.UNKNOWN ? '' : response?.encodingType,
        } as unknown) as IEnterprise
        commit(SAVE_ENTERPRISE, enterprise)
      } catch (err) {
        throw err
      }
    },
  },
}
