/* eslint-disable no-useless-catch */
import { getBarcodeImports, fetchProject } from '@/utils/api'
import { showErrorMessage } from '@/utils/helpers'
import {
  IBarcodeImport,
  IBarcodeImportList,
  IBarcodeImportSearchPayload,
  IProject,
} from 'smartbarcode-web-core/src/utils/types/index'

import {
  LOAD_BARCODE_IMPORT_LIST,
  SET_BARCODE_IMPORT_LOADING,
  CLEAR_BARCODE_IMPORT_LOADING,
  CLEAR_ALL,
  LOAD_BARCODE_IMPORT_PROJECT,
} from './actions'

type TBarcodeImportStates = {
  isLoaded: boolean
  projectCode: string
  totalBarcodes: number
  barcodeImports: Array<IBarcodeImportList>
  projectList: IProject
  barcodeImport: IBarcodeImport
  loading: boolean
}

export default {
  state: {
    isLoaded: false,
    dataSearch: {
      projectId: '',
    },
    barcodeImports: [],
    totalBarcodes: 0,
    barcodeImport: {} as IBarcodeImport,
    projectList: {} as IProject,
    loading: false,
  },
  mutations: {
    [LOAD_BARCODE_IMPORT_LIST]: (
      state: TBarcodeImportStates,
      {
        barcodeImports,
        totalBarcodes,
        projectCode,
        project,
      }: {
        barcodeImports: Array<IBarcodeImportList>
        totalBarcodes: number
        projectCode: string
        project: IProject
      }
    ) => {
      state.isLoaded = true
      state.barcodeImports = barcodeImports
      state.totalBarcodes = totalBarcodes
      state.projectCode = projectCode
      state.projectList = project
    },
    [LOAD_BARCODE_IMPORT_PROJECT]: (
      state: TBarcodeImportStates,
      {
        project,
      }: {
        project: IProject
      }
    ) => {
      state.isLoaded = true
      state.projectList = project
    },
    [SET_BARCODE_IMPORT_LOADING]: (state: TBarcodeImportStates) => {
      state.loading = true
    },
    [CLEAR_BARCODE_IMPORT_LOADING]: (state: TBarcodeImportStates) => {
      state.loading = false
    },
    [CLEAR_ALL]: (state: TBarcodeImportStates) => {
      state.isLoaded = false
      state.projectCode = ''
      state.barcodeImports = []
      state.barcodeImport = {} as IBarcodeImport
      state.projectList = {} as IProject
    },
  },
  actions: {
    [LOAD_BARCODE_IMPORT_LIST]: async (
      {
        commit,
      }: {
        commit: Function
      },
      data: IBarcodeImportSearchPayload
    ) => {
      let project, result
      try {
        commit(SET_BARCODE_IMPORT_LOADING)
        project = await fetchProject(data.projectCode, String(data.version))
        result = await getBarcodeImports(data)

        commit(LOAD_BARCODE_IMPORT_LIST, {
          barcodeImports: result.barcodeImports,
          totalBarcodes: result.count,
          projectCode: data.projectCode,
          project,
        })
      } catch (err) {
        switch (err) {
          case '2310':
            break
          default:
            if ((err as Record<string, string>).projectCode) {
              showErrorMessage((err as Record<string, string>).projectCode, data.t, data.te)
            } else {
              showErrorMessage(err as string, data.t, data.te)
            }
            break
        }
        commit(LOAD_BARCODE_IMPORT_LIST, {
          barcodeImports: [],
          projectCode: data.projectCode,
          project: project,
        })
      } finally {
        commit(CLEAR_BARCODE_IMPORT_LOADING)
      }
    },
    [LOAD_BARCODE_IMPORT_PROJECT]: async (
      {
        commit,
        state,
      }: {
        commit: Function
        state: TBarcodeImportStates
      },
      data: IBarcodeImportSearchPayload
    ) => {
      let project = state.projectList
      try {
        commit(SET_BARCODE_IMPORT_LOADING)

        project = await fetchProject(data.projectCode, String(data.version))

        commit(LOAD_BARCODE_IMPORT_PROJECT, {
          project,
        })
      } catch (err) {
        switch (err) {
          case '2310':
            break
          default:
            if ((err as Record<string, string>).projectCode) {
              showErrorMessage((err as Record<string, string>).projectCode, data.t, data.te)
            } else {
              showErrorMessage(err as string, data.t, data.te)
            }
            break
        }
        commit(LOAD_BARCODE_IMPORT_PROJECT, { project })
      } finally {
        commit(CLEAR_BARCODE_IMPORT_LOADING)
      }
    },
  },
}
