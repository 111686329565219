<template>
  <SvgBase iconName="IconBarcodeScanFrame" width="17" height="16">
    <g clip-path="url(#clip0_10331_8049)">
      <path d="M5.66667 5H3V7.66667H5.66667V5Z" :fill="color" />
      <path d="M8.33366 7.66797H5.66699V10.3346H8.33366V7.66797Z" :fill="color" />
      <path d="M5.66667 10.332H3V12.9987H5.66667V10.332Z" :fill="color" />
      <path d="M10.9997 10.332H8.33301V12.9987H10.9997V10.332Z" :fill="color" />
    </g>
    <path
      d="M17 2V7.14286C17 7.61607 16.6221 8 16.1562 8H8.84375C8.37793 8 8 7.61607 8 7.14286V2C8 1.52679 8.37793 1.14286 8.84375 1.14286H10.3906L10.6068 0.555357C10.7299 0.221429 11.0445 0 11.3961 0H13.6021C13.9537 0 14.2684 0.221429 14.3914 0.555357L14.6094 1.14286H16.1562C16.6221 1.14286 17 1.52679 17 2ZM14.6094 4.57143C14.6094 3.38929 13.6637 2.42857 12.5 2.42857C11.3363 2.42857 10.3906 3.38929 10.3906 4.57143C10.3906 5.75357 11.3363 6.71429 12.5 6.71429C13.6637 6.71429 14.6094 5.75357 14.6094 4.57143ZM14.0469 4.57143C14.0469 5.4375 13.3525 6.14286 12.5 6.14286C11.6475 6.14286 10.9531 5.4375 10.9531 4.57143C10.9531 3.70536 11.6475 3 12.5 3C13.3525 3 14.0469 3.70536 14.0469 4.57143Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 2H3C1.34315 2 0 3.34315 0 5V13C0 14.6569 1.34315 16 3 16H11C12.6569 16 14 14.6569 14 13V8H13V13C13 14.1046 12.1046 15 11 15H3C1.89543 15 1 14.1046 1 13V5C1 3.89543 1.89543 3 3 3H9V2Z"
      :fill="color"
    />
    <defs>
      <clipPath id="clip0_10331_8049">
        <rect width="8" height="8" fill="white" transform="translate(3 5)" />
      </clipPath>
    </defs>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconBarcodeScanFrame extends Vue {}
</script>
