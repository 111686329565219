import {
  getAddresses,
  getCountries,
  getProfile,
  getUserList,
  impersonate,
  login,
  logout,
  setOrganizationWhiteList,
  unimpersonate,
} from '@/utils/api'
import { IImpersonateUserPayload, IVisitor } from '@/utils/apiPayloadInterface'
import { LANGUAGE_SET } from '@/utils/constants'
import { getVisitorId } from '@/utils/helpers'
import { ESmartBarcodeMenu, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IAddress,
  IImpersonatable,
  IIpRange,
  ILoginPayload,
  IOrganization,
  ISelectData,
  IUser,
} from 'smartbarcode-web-core/src/utils/types/index'
import {
  CLEAR_ALL,
  CLEAR_LOADING,
  CLEAR_PROFILE,
  FETCH_ADDRESSES,
  FETCH_COUNTRIES,
  FETCH_PROFILE,
  FETCH_USER_LIST,
  IMPERSONATE,
  LOGIN,
  LOGOUT,
  OPEN_DIALOG,
  SAVE_COUNTRIES,
  SAVE_PROFILE,
  SAVE_USER_LIST,
  SAVE_WHITE_IP_LIST,
  SET_LOADING,
  UNIMPERSONATE,
} from './actions'

type TProfileState = {
  userList: ISelectData[] | null
  isLoadedUserList: boolean
  user: IUser | null
  organization: IOrganization | null
  originUser: IUser | null
  impersonatables: IImpersonatable[]
  addressBook: Array<IAddress>
  addressHistory: Array<IAddress>
  enterprise: string
  getProfileOnInit: boolean
  countries: Array<string>
  isLoadCountry: boolean
  language: string
  loading: boolean
}

function clearState(state: TProfileState) {
  state.userList = []
  state.isLoadedUserList = false
  state.user = null
  state.impersonatables = []
  state.originUser = null
  state.addressBook = []
  state.addressHistory = []
  state.enterprise = ''
  state.countries = []
  state.isLoadCountry = false
  state.loading = false
  state.language = LANGUAGE_SET.language[1]
}

export default {
  state: {
    userList: [],
    isLoadedUserList: false,
    user: null,
    addressBook: [],
    addressHistory: [],
    enterprise: '',
    getProfileOnInit: true,
    countries: [],
    isLoadCountry: false,
    loading: false,
    language: LANGUAGE_SET.language[1],
  },
  getters: {
    isAuth: (state: TProfileState) => {
      return !!state.user
    },
    isEnableArchive: (state: TProfileState) => {
      return !!state?.organization?.isArchiveBarcodePermission
    },
    isEnableTimetable: (state: TProfileState) => {
      return !!state?.organization?.enableTimetable
    },
    isTradeWaltzPermission: (state: TProfileState) => {
      return !!state?.organization?.isTradeWaltzPermission
    },
    isEnableNotificationSetting: (state: TProfileState) => {
      return !!state?.organization?.enableTrackPointRouteNotificationSetting
    },
    isLoading: (state: TProfileState) => {
      return state.loading
    },
    userType: (state: TProfileState) => {
      return state.user?.userType
    },
    whiteListIp: (state: TProfileState) => {
      return state.organization?.whiteListIp
    },
    isEnableSearchTrackingData: (state: TProfileState) => {
      return state.organization?.enableSearchTrackingData
    },
    userId: (state: TProfileState) => {
      return state.user?.id
    },
    alreadyGetProfileOnInit: (state: TProfileState) => {
      return !state.getProfileOnInit
    },
  },
  mutations: {
    [SAVE_WHITE_IP_LIST]: (state: TProfileState, whiteListIp: IIpRange[]) => {
      if (state.organization) {
        state.organization.whiteListIp = whiteListIp
        state.loading = false
      }
    },
    [SAVE_PROFILE]: (
      state: TProfileState,
      {
        user,
        organization,
        originUser,
        impersonatables,
      }: {
        user: IUser
        organization: IOrganization
        originUser: IUser
        impersonatables: IImpersonatable[]
      }
    ) => {
      state.user = user
      state.organization = organization
      state.originUser = originUser
      state.impersonatables = impersonatables
      state.getProfileOnInit = false
      state.loading = false
    },
    [CLEAR_PROFILE]: (state: TProfileState) => {
      clearState(state)
    },
    [SET_LOADING]: (state: TProfileState) => {
      state.loading = true
    },
    [CLEAR_LOADING]: (state: TProfileState) => {
      state.loading = false
    },
    [CLEAR_ALL]: (state: TProfileState) => {
      clearState(state)
    },
    [SAVE_COUNTRIES]: (
      state: TProfileState,
      {
        countries,
      }: {
        countries: Array<string>
      }
    ) => {
      state.countries = countries
      state.isLoadCountry = true
    },
    [SAVE_USER_LIST]: (
      state: TProfileState,
      {
        userList,
      }: {
        userList: Array<ISelectData>
      }
    ) => {
      state.userList = userList
      state.isLoadedUserList = true
    },
  },
  actions: {
    [FETCH_PROFILE]: async (
      {
        commit,
        dispatch,
        state,
      }: {
        commit: Function
        dispatch: Function
        state: TProfileState
      },
      payload: { force: boolean }
    ) => {
      try {
        if (!state?.user || payload?.force) {
          commit(SET_LOADING)

          const response = await getProfile()

          if (!response) return
          const { user, impersonatables, originUser, organization } = response
          user.entitledPortalUIViews = user?.entitledPortalUIViews ?? ([] as string[])
          if (user.userType === EUserType.ENTERPRISE) {
            user.entitledPortalUIViews.push(ESmartBarcodeMenu.ENTERPRISE)
          } else if (user.userType === EUserType.CLIENT) {
            user.entitledPortalUIViews = [
              ...user.entitledPortalUIViews,
              ...[ESmartBarcodeMenu.PRODUCT, ESmartBarcodeMenu.ADDRESS_BOOK],
            ]

            // Support search for client
            if (organization.setDisplayedBarcodeByClient) {
              user.entitledPortalUIViews.push(ESmartBarcodeMenu.BARCODE_SEARCH)
            }
          }

          commit(SAVE_PROFILE, { user, organization, originUser, impersonatables })
        }
      } catch (error) {
        const ex: Error = error as Error
        if (ex.message) {
          dispatch(OPEN_DIALOG, {
            message: ex.message,
            needtranslation: true,
          })

          dispatch(LOGOUT)
        } else {
          commit(CLEAR_PROFILE)
        }
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [SAVE_WHITE_IP_LIST]: async (
      {
        commit,
        dispatch,
        state,
      }: {
        commit: Function
        dispatch: Function
        state: TProfileState
      },
      data: IIpRange[]
    ) => {
      try {
        commit(SET_LOADING)

        const response = await setOrganizationWhiteList({
          whiteListIp: data,
        })

        if (!response) return
        commit(SAVE_WHITE_IP_LIST, response)
      } catch (error) {
        const ex: Error = error as Error
        if (ex.message) {
          dispatch(OPEN_DIALOG, {
            message: ex.message,
            needtranslation: true,
          })
        } else {
          throw error
        }
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [LOGOUT]: async ({ commit }: { commit: Function }) => {
      await logout()
      commit(CLEAR_ALL)
    },
    [LOGIN]: async (
      {
        commit,
        dispatch,
      }: {
        commit: Function
        dispatch: Function
      },
      payload: ILoginPayload
    ) => {
      /* eslint-disable */
      try {
        commit(SET_LOADING)
        await login(payload)

        dispatch(FETCH_PROFILE)
      } catch (error) {
        throw error
      } finally {
        commit(CLEAR_LOADING)
      }
      /* eslint-enable */
    },
    [FETCH_COUNTRIES]: async ({ commit }: { commit: Function }) => {
      try {
        commit(SET_LOADING)
        const countries: Array<string> = await getCountries()
        commit(SAVE_COUNTRIES, { countries })
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [FETCH_ADDRESSES]: async ({ commit }: { commit: Function }) => {
      try {
        commit(SET_LOADING)
        return await getAddresses()
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [FETCH_USER_LIST]: async ({ commit }: { commit: Function }) => {
      try {
        commit(SET_LOADING)
        const userList: Array<ISelectData> = await getUserList()

        commit(SAVE_USER_LIST, { userList })
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [IMPERSONATE]: async (
      {
        commit,
        state,
        dispatch,
      }: {
        commit: Function
        state: TProfileState
        dispatch: Function
      },
      impersonateUser: IUser
    ) => {
      try {
        commit(SET_LOADING)

        await impersonate({
          impersonateUserId: impersonateUser.id,
          visitorId: await getVisitorId(),
        } as IImpersonateUserPayload)

        await commit(CLEAR_ALL)
        let count = 0
        do {
          await dispatch(FETCH_PROFILE, { force: true })
        } while (state.user?.id !== impersonateUser.id && ++count < 10)
      } finally {
        commit(CLEAR_LOADING)
      }
    },
    [UNIMPERSONATE]: async ({
      commit,
      dispatch,
      state,
    }: {
      commit: Function
      dispatch: Function
      state: TProfileState
    }) => {
      try {
        commit(SET_LOADING)

        const originalUserId = state?.originUser?.id
        await unimpersonate({ visitorId: await getVisitorId() } as IVisitor)
        let count = 0
        do {
          await dispatch(FETCH_PROFILE, { force: true })
        } while (state.user?.id !== originalUserId && ++count < 10)
        await commit(CLEAR_ALL)
      } finally {
        commit(CLEAR_LOADING)
      }
    },
  },
}
