<template>
  <SvgBase iconName="IconUser">
    <g fill="none">
      <path
        d="M8.28571 0.214355C3.70963 0.214355 0 3.92398 0 8.50007C0 13.0762 3.70963 16.7858 8.28571 16.7858C12.8618 16.7858 16.5714 13.0762 16.5714 8.50007C16.5714 3.92398 12.8618 0.214355 8.28571 0.214355ZM8.28571 1.81804C11.9786 1.81804 14.9677 4.80661 14.9677 8.50007C14.9677 9.65205 14.6769 10.7355 14.1646 11.6811C13.7168 10.7994 12.9108 10.0928 11.9133 9.79107C12.3681 9.09507 12.6123 8.28183 12.6123 7.43094C12.6123 5.03981 10.6772 3.10433 8.28571 3.10433C5.89458 3.10433 3.9591 5.03941 3.9591 7.43094C3.9591 8.28183 4.20333 9.09507 4.65817 9.79107C3.66185 10.0924 2.8553 10.7981 2.40683 11.6811C1.89456 10.7356 1.60369 9.65212 1.60369 8.50007C1.60369 4.80721 4.59226 1.81804 8.28571 1.81804ZM5.6129 7.43094C5.6129 5.95478 6.80955 4.75813 8.28571 4.75813C9.76187 4.75813 10.9585 5.95478 10.9585 7.43094C10.9585 8.9071 9.76187 10.1038 8.28571 10.1038C6.80955 10.1038 5.6129 8.9071 5.6129 7.43094ZM3.61073 13.2758C3.66148 12.1399 4.59814 11.2346 5.74654 11.2346H6.22C7.50084 11.9325 9.07112 11.9322 10.3515 11.2346H10.8249C11.9733 11.2346 12.9099 12.1399 12.9607 13.2758C10.3632 15.8188 6.20663 15.8172 3.61073 13.2758Z"
        fill="#525252"
      />
    </g>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUser extends Vue {}
</script>
