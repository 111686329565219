/* eslint-disable no-useless-catch */

import {
  CLEAR_ALL,
  FETCH_ALL_TW_PROCESS,
  SAVE_TW_BINDING_SETTING,
  SAVE_TW_PROCESS,
  SET_DISPLAY_PAGE,
} from '@/store/actions'

import { getTWProcessList } from '@/utils/api'
import { EProcessID } from 'smartbarcode-web-core/src/utils/enums'
import { ITWProcess, IBindingSetting } from 'smartbarcode-web-core/src/utils/types'

const enum ETradeWaltzPage {
  PURCHASE_ORDER_TAB = 'purchaseOrderTab',
  BOOKING_REQ_TAB = 'bookingReqTab',
  STORE_BL_REQ_TAB = 'storeBLReqTab',
}

const enum ETradeWaltzSubPage {
  MAIN = 'main',
  DETAIL = 'detail',
  CREATE = 'create',
}

type TTradeWaltzStore = {
  navigation: {
    currentPage: ETradeWaltzPage
    subPage: ETradeWaltzSubPage
  }
  processesData: ITWProcess[]
  bindingSettings: {
    reqPOBindingSettings: IBindingSetting
    reqBookingBindingSettings: IBindingSetting
    storeBLBindingSettings: IBindingSetting
  }
  reqPOBindingSettings: IBindingSetting
  reqBookingBindingSettings: IBindingSetting
  storeBLBindingSettings: IBindingSetting
}

export default {
  state: {
    navigation: {
      currentPage: ETradeWaltzPage.PURCHASE_ORDER_TAB,
      subPage: ETradeWaltzSubPage.MAIN,
    },
    processesData: [],
    bindingSettings: {
      reqPOBindingSettings: null,
      reqBookingBindingSettings: null,
      storeBLBindingSettings: null,
    },
  },
  getters: {
    getPOProcessList: (state: TTradeWaltzStore) => {
      return state.processesData.filter((val) => {
        return val.processId === EProcessID.SEND_PO
      })
    },
    getBookingReqProcessList: (state: TTradeWaltzStore) => {
      return state.processesData.filter((val) => {
        return val.processId === EProcessID.REQUEST_BOOKING_TPA
      })
    },
    getStoreBLProcessList: (state: TTradeWaltzStore) => {
      return state.processesData.filter((val) => {
        return val.processId === EProcessID.STORE_BILL_OF_LADING
      })
    },
  },
  mutations: {
    [SET_DISPLAY_PAGE]: (
      state: TTradeWaltzStore,
      {
        navigation,
      }: {
        navigation: {
          currentPage: ETradeWaltzPage
          subPage: ETradeWaltzSubPage
        }
      }
    ) => {
      const nav = { ...state.navigation, ...navigation }
      state.navigation = nav
    },
    [SAVE_TW_PROCESS]: (
      state: TTradeWaltzStore,
      {
        processesData,
      }: {
        processesData: ITWProcess[]
      }
    ) => {
      if (processesData) {
        state.processesData = processesData
      }
    },
    [CLEAR_ALL]: (state: TTradeWaltzStore) => {
      state.navigation.currentPage = ETradeWaltzPage.PURCHASE_ORDER_TAB
      state.navigation.subPage = ETradeWaltzSubPage.MAIN
    },
    [SAVE_TW_BINDING_SETTING]: (
      state: TTradeWaltzStore,
      {
        reqPOBindingSettings,
        reqBookingBindingSettings,
        storeBLBindingSettings,
      }: {
        reqPOBindingSettings: IBindingSetting
        storeBLBindingSettings: IBindingSetting
        reqBookingBindingSettings: IBindingSetting
      }
    ) => {
      if (reqPOBindingSettings) {
        state.bindingSettings.reqPOBindingSettings = reqPOBindingSettings
      }
      if (reqBookingBindingSettings) {
        state.bindingSettings.reqBookingBindingSettings = reqBookingBindingSettings
      }
      if (storeBLBindingSettings) {
        state.bindingSettings.storeBLBindingSettings = storeBLBindingSettings
      }
      console.log(state.bindingSettings)
    },
  },
  actions: {
    [FETCH_ALL_TW_PROCESS]: async ({ commit }: { commit: Function }) => {
      try {
        const processData = await getTWProcessList()
        commit(SAVE_TW_PROCESS, { processesData: processData })
      } catch (err) {
      } finally {
      }
    },
  },
}
