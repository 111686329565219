<template>
  <SvgBase iconName="Textbox" width="14" height="14">
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 3.5L10.5 3.5V5.25H7H3.5V3.5ZM6.125 5.25H7.875V10.5H6.125V5.25Z"
        fill="black"
      />
      <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" stroke="black" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconTextbox extends Vue {}
</script>
