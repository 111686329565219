import { CLEAR_ALL, SAVE_PERMISSION } from './actions'

type TPermissionDialogState = {
    checkedUser: Record<string, string[]>
}

export default {
  state: {
    checkedUser: {},
  },
  getters: {
  },
  mutations: {
    [SAVE_PERMISSION]: (state: TPermissionDialogState, payload: Record<string, string[]>) => {
        state.checkedUser = { ...state.checkedUser, ...payload }
    },
    [CLEAR_ALL]: (state: TPermissionDialogState) => {
      state.checkedUser = {}
    },
  },
  actions: {
  },
}
