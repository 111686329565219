<template>
  <SvgBase iconName="MenuBarcode" :width="24" :height="24">
    <path
      d="M11 12.5H9.5V11.5H12.5V14.5H11.5V13V12.5H11ZM16.5 4H16V4.5V7.5V8H16.5H19.5H20V7.5V4.5V4H19.5H16.5ZM4.5 4H4V4.5V7.5V8H4.5H7.5H8V7.5V4.5V4H7.5H4.5ZM4.5 16H4V16.5V19.5V20H4.5H7.5H8V19.5V16.5V16H7.5H4.5ZM3.5 11.5H4.5V12.5H3.5V11.5ZM11.5 5.5H12.5V8.5H11.5V5.5ZM15.5 3.5H20.5V8.5H15.5V3.5ZM3.5 3.5H8.5V8.5H3.5V3.5ZM3.5 15.5H8.5V20.5H3.5V15.5Z"
      fill="#5A6AF1"
    />
    <path
      d="M20.5868 18.4296L20.316 18.781L20.6327 19.0917L23.3893 21.796L22.7718 22.4017L19.9573 19.6407L19.6719 19.3607L19.3359 19.5776C18.5371 20.0935 17.5833 20.3952 16.553 20.3952C13.7533 20.3952 11.5 18.1711 11.5 15.4476C11.5 12.7242 13.7533 10.5 16.553 10.5C19.3527 10.5 21.606 12.7242 21.606 15.4476C21.606 16.5672 21.2261 17.5999 20.5868 18.4296ZM12.5193 15.4476C12.5193 17.6487 14.3329 19.4143 16.553 19.4143C18.7731 19.4143 20.5868 17.6487 20.5868 15.4476C20.5868 13.2465 18.7731 11.481 16.553 11.481C14.3329 11.481 12.5193 13.2465 12.5193 15.4476Z"
      fill="#5A6AF1"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconMenuBarcode extends Vue {}
</script>
