<template>
  <SvgBase iconName="Location" width="14" height="16" fill="none">
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6 8.7C7.7598 8.7 8.7 7.7598 8.7 6.6C8.7 5.4402 7.7598 4.5 6.6 4.5C5.4402 4.5 4.5 5.4402 4.5 6.6C4.5 7.7598 5.4402 8.7 6.6 8.7Z"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.6 1C5.11479 1 3.69041 1.59 2.6402 2.6402C1.59 3.69041 1 5.11479 1 6.6C1 7.9244 1.2814 8.791 2.05 9.75L6.6 15L11.15 9.75C11.9186 8.791 12.2 7.9244 12.2 6.6C12.2 5.11479 11.61 3.69041 10.5598 2.6402C9.50959 1.59 8.08521 1 6.6 1V1Z"
        :stroke="color"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from '../SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconLocation extends Vue {}
</script>
